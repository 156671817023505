import React from 'react'
import {Button, Image} from 'antd'
import { styles } from './Form'
import DCMLogo from './images/logoDCM2.png'

function ForgotPassword(props) {
  return (
    <div style={styles.container}>
      <Image
        src={DCMLogo}
        preview={false}
      />
      <input
        name='username'
        placeholder='Username'
        onChange={e => {e.persist();props.updateFormState(e)}}
        style={styles.input}
      />
      <Button onClick={props.forgotPassword}>Reset password</Button>
    </div>
  )
}

export default ForgotPassword