import React from 'react';
import { TileLayout } from "@progress/kendo-react-layout";

const CustomHeader = () => (
  <div style={{ backgroundColor: '#f0f0f0', padding: '10px', fontWeight: 'bold' }}>
    Custom Header
  </div>
);

const Test = () => {
  const tiles = [
    {
      header: <CustomHeader />,
      body: <div>Tile Content 1</div>,
    },
    {
      header: <CustomHeader />,
      body: <div>Tile Content 2</div>,
    },
  ];

  return (
    <TileLayout
      columns={2}
      rowHeight={150}
      gap={{ rows: 10, columns: 10 }}
    />
  );
};

export default Test;
