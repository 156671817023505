import React from 'react'
import {Button, Image} from 'antd'
import { styles } from './Form'
import DCMLogo from './images/logoDCM2.png'

function ConfirmSignUp(props) {
  return (
    <div style={styles.container}>
      <Image
        src={DCMLogo}
        preview={false}
      />
      <p>A verification code was sent to your email.</p>
      <p>Please enter the verification code to complete the signup process</p>
      <input
        name='confirmationCode'
        placeholder='Confirmation Code'
        onChange={e => {e.persist();props.updateFormState(e)}}
        style={styles.input}
      />
      <Button onClick={props.confirmSignUp}>Confirm Sign Up</Button>
    </div>
  )
}

export default ConfirmSignUp