import React,{useEffect, useState} from 'react'
import protectedRoute from '../protectedRoute'
import { useNavigate, Link } from 'react-router-dom';
import '@progress/kendo-theme-bootstrap/dist/all.css';
import { TileLayout } from "@progress/kendo-react-layout";
import { AlertOutlined, CheckCircleOutlined, CloseOutlined, DatabaseOutlined, EditOutlined, InfoOutlined,
          PoweroffOutlined, MailOutlined,  MessageOutlined, UploadOutlined, 
          UserAddOutlined } from '@ant-design/icons'
import { Steps, Image, Input, Form, Modal, Checkbox, DatePicker, DatePickerProps, TimePicker, Select, Tooltip, notification } from 'antd';

import { convertNiceDateToSql, convertNiceAmtToSql, showIfExisting, BWN,
  getFileFromS3, renderStoredSysCodes, pageHeaderStyle, itemStyle } from '../Utils/utils'

import moment from 'moment';
import './Claim.css';
import { Auth } from 'aws-amplify';

import {getUserToken} from '../misc/authHelper'

// const { Option } = Select;

//============================================
// CODE BLOCKS
//============================================
//  CLAIMHOME props
//  useEffect
//  getClaim
//  CLIENT PRODUCT DATA
//  PARTNERS AND PARTNERPERSONS DATA
//  CLAIMROLES DROPDOWN DATA
//  SYSCODE DATA
//  STYLES
//  searchBox
//  claimHeader
//  renderClaimValue
//  renderClaimDetails
//  renderClaimMedicalDetails
//  EditClaimForm
//  _setEditClaimDataItem
//  renderClaimRoles
//  _getClaimRoleData
//  ClaimRoleForm
//  _setClaimRoleDataItem
//  EditStepForm
//  _setEditStepDataItem
//  renderSteps
//  renderComplaints
//  renderServiceCosts
//  LOG FILTERS AND ICONS
//  renderTileHeader
//  SEARCH LOGS
//  renderLogs
//  setFormVisible
//  EditLogForm
//  _setEditLogDataItem
//  renderReminders
//  renderDocFiles
//  renderLatestImages
//  renderDocFolders
//  _sendEmail
//  EmailForm
//  _setEmail
//  _sendSMS
//  SMSForm
//  positions4K and claimPositions
//  claimTiles and tiles4K
//  handleReposition
//  MAIN RENDER
//============================================


//=======================================================================
function ClaimHome(props) {
//=======================================================================
  const navigate = useNavigate();
  // const location = useLocation();
  
  const [userName, setUserName] = useState(localStorage.getItem('userEmail'))

  // let claimID
  var claimID = localStorage.getItem("claimId")
  const [ClaimID, setClaimID] = useState(claimID)
 
  const [serviceType, setServiceType] = useState("")

  const [googleMapsCall, setGoogleMapsCall] = useState('')
  const [riskAddress, setRiskAddress] = useState('')

  const [isLoading, setIsLoading] = useState(true)
  // const [currClaim, setCurrClaim] = useState([])
  const [currEditClaim, setCurrEditClaim] = useState('')
  const [currEditClaimRole, setCurrEditClaimRole] = useState('')
  const [isEditClaimFormVisible, setEditClaimFormVisible] = useState(false);
  // const [isComplaintFormVisible, setComplaintFormVisible] = useState(false);
  const [isClaimRoleFormVisible, setClaimRoleFormVisible] = useState(false);

  // const [isNewProviderSubFormVisible, setNewProviderSubFormVisible] = useState(false);
  // const [isNewPartnerPersonSubFormVisible, setNewPartnerPersonSubFormVisible] = useState(false);

  const [isEditLogFormVisible, setEditLogFormVisible] = useState(false);
  // const [currClaimLogID, setClaimLogID] = useState(false)
  
  const [isEditReminderFormVisible, setEditReminderFormVisible] = useState(false);
  // const [currReminderID, setReminderID] = useState(false)

  const [isEditStepFormVisible, setEditStepFormVisible] = useState(false);
  const [step, setStep] = useState(false)
  const [currStep, setCurrStep] = useState()
  const [currStepData, setCurrStepData] = useState('')

  const [isEmailFormVisible, setEmailFormVisible] = useState(false);
  const [isSmsFormVisible, setSmsFormVisible] = useState(false);

  // const { Search } = Input;
  // const [searchString, setSearchString] = useState('') 
  // const [searchComms, setSearchComms] = useState('') 
  // const [searchLogs, setSearchLogs] = useState('')

  const { TextArea } = Input;
  const { Option } = Select;

  //Tiles State Data
  const [claimHdrData, setClaimHdrData] = useState([]) 
  const [logData, setLogData] = useState([])
  const [remindersData, setRemindersData] = useState([])
  // const [docfilesTileData, setdocfilesTileData] = useState([])

  const [servicecostsTileData, setServicecostsTileData] = useState([])
  const [referralsData, setReferralsData] = useState([])

  const [allDocsData, setAllDocsData] = useState([])
  // const [docfoldersTileData, setDocfoldersTileData] = useState([])
  // const [complaintsData, setComplaintsData] = useState([])
  // const [appointmentsData, setAppointmentsData] = useState([])
  // const [linksData, setLinksData] = useState([])

  const [stepsData, setStepsData] = useState([])
  const [dccSLAsData, setDccSLAsData] = useState([])
  

  // const [commData, setCommData] = useState([])
  const [estimsData, setEstimsData] = useState([])
  const [invsData, setinvsData] = useState([])

  const [estimsAmt, setEstimsAmt] = useState('')
  const [estimsInvoicedAmt, setEstimsInvoicedAmt] = useState('')
  const [estimsPaidAmt, setEstimsPaidAmt] = useState('')
  const [invsAmt, setInvsAmt] = useState('')
  const [paidInvsAmt, setPaidInvsAmt] = useState('')
  const [balanceInvsAmt, setBalanceInvsAmt] = useState('')

  const [claimrolesData, setClaimrolesData] = useState([])
  const [dcmPeopleData, setDcmPeopleData] = useState([])

  const [photosData, setPhotosData] = useState([])
  const [docfoldersData, setDocfoldersData] = useState([])

  // const [emailData, setEmailData] = useState([])

  const [filteredLogs, setFilteredLogs] = useState(logData)
  
  const [currClaimRole, setCurrClaimRole] = useState()
  // const [currReminder, setCurrReminder] = useState([])

  const [rolePartnersData, setRolePartnersData] = useState([]);
  const [rolePartnerPersonsData, setRolePartnerPersonsData] = useState([]);

  // const clientProductsData = localStorage.getItem('clientProducts')
  // const clientProductsData = require("../data/ClientProducts.json");
  
  
  //=======================================================================
  //  useEffect
  //=======================================================================
  useEffect(() => {

    _getClaim()

  },[ClaimID])

  
//=======================================================================
//  UTILITY FUNCTIONS
//=======================================================================

//---------------------------------------------------------------------
  const BWN = ( inStr ) => {
  //---------------------------------------------------------------------
  if (!inStr) inStr = "";
  if (inStr === "undefined") inStr = "";
  if ( !Number.isNaN(Number(inStr)) ) inStr = inStr.toString();
    return inStr;
  };
//---------------------------------------------------------------------



  //=======================================================================
  //  getClaim
  //=======================================================================
  const _getClaim = async () => {
    console.log("_getClaim", ClaimID)
    // if(!ClaimID) {
    //   let claimId = localStorage.getItem("claimId")
    //   setClaimID(claimId)
    // }
    // localStorage.setItem('claimId', ClaimID)

    setIsLoading(true)

    // var UserEmail = "system.admin@davidcoreymedical.com"
    var UserEmail = localStorage.getItem("userEmail")
    console.log(UserEmail)

    let tmpClaimInfo
    setIsLoading(true)
    await fetch(process.env.REACT_APP_BASE_URL + "getClaim", {
      body: JSON.stringify(
        {
          loginId: UserEmail,
          action: "",
          claimId: ClaimID.toString()
        }
      ),
           headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
       "accesstoken": getUserToken()
      },
      method: "POST"
    })
    .then((response) => response.json())
    .then((responseJSON) => tmpClaimInfo = responseJSON)
    // setCurrClaim(tmpClaimInfo)

    // console.log(tmpClaimInfo)

    setClaimHdrData(tmpClaimInfo[0])
    setUserName(tmpClaimInfo[0][0].UserName)
    localStorage.setItem('DocTree', tmpClaimInfo[0][0].DocTree)

    setCurrEditClaim({
      modifier: 'public',
      DccOrDcm: tmpClaimInfo[0][0].DccOrDcm,
      ProdShortName: tmpClaimInfo[0][0].ProdShortName,
      ProductID: tmpClaimInfo[0][0].ProductID,
      ClaimNo: tmpClaimInfo[0][0].ClaimNo,
      GClaimID: tmpClaimInfo[0][0].GClaimID,

      AdjusterRef: tmpClaimInfo[0][0].AdjusterRef,
      ReferralTypes: tmpClaimInfo[0][0].ReferralTypes,

      IW_Title: tmpClaimInfo[0][0].IW_Title,
      IW_Given: tmpClaimInfo[0][0].IW_Given,
      IW_Middle: tmpClaimInfo[0][0].IW_Middle,
      IW_Surname: tmpClaimInfo[0][0].IW_Surname,
      IW_PhoneH: tmpClaimInfo[0][0].IW_PhoneH,
      IW_Cell: tmpClaimInfo[0][0].IW_Cell,
      IW_email: tmpClaimInfo[0][0].IW_email,
      IW_Address1: tmpClaimInfo[0][0].IW_Address1,
      IW_Address2: tmpClaimInfo[0][0].IW_Address2,
      IW_City: tmpClaimInfo[0][0].IW_City,
      IW_State: tmpClaimInfo[0][0].IW_State,
      IW_Jurisdiction: tmpClaimInfo[0][0].IW_Jurisdiction,
      IW_Zipcode: tmpClaimInfo[0][0].IW_Zipcode,
      IW_DOB: tmpClaimInfo[0][0].IW_DOB,
      IW_Gender: tmpClaimInfo[0][0].IW_Gender,
      IW_Height: tmpClaimInfo[0][0].IW_Height,
      IW_Weight: tmpClaimInfo[0][0].IW_Weight,
      IW_SSN: tmpClaimInfo[0][0].IW_SSN,
      IW_Preferred: tmpClaimInfo[0][0].IW_Preferred,
      IW_PrefLang: tmpClaimInfo[0][0].IW_PrefLang,
      IWC_Title: tmpClaimInfo[0][0].IWC_Title,
      IWC_Given: tmpClaimInfo[0][0].IWC_Given,
      IWC_Middle: tmpClaimInfo[0][0].IWC_Middle,
      IWC_Surname: tmpClaimInfo[0][0].IWC_Surname,
      IWC_PhoneH: tmpClaimInfo[0][0].IWC_PhoneH,
      IWC_Cell: tmpClaimInfo[0][0].IWC_Cell,
      IWC_email: tmpClaimInfo[0][0].IWC_email,
      IWC_Relship: tmpClaimInfo[0][0].IWC_Relship,

      IW_HospitalName: tmpClaimInfo[0][0].IW_HospitalName,
      IW_HospitalRoomNo: tmpClaimInfo[0][0].IW_HospitalRoomNo,

      IW_ICDCode: tmpClaimInfo[0][0].IW_ICDCode,
      MED_Diagnosis: tmpClaimInfo[0][0].MED_Diagnosis,
      MED_Rx: tmpClaimInfo[0][0].MED_Rx,
      MED_DrOrders: tmpClaimInfo[0][0].MED_DrOrders,
      MED_Other: tmpClaimInfo[0][0].MED_Other,
      DCH_Comments: tmpClaimInfo[0][0].DCH_Comments,
      IW_Comments: tmpClaimInfo[0][0].IW_Comments,

      IW_DOBDT: tmpClaimInfo[0][0].IW_DOBDT,
      MED_InjuryDT: tmpClaimInfo[0][0].MED_InjuryDT,
      DCH_DischargeDT: tmpClaimInfo[0][0].DCH_DischargeDT,

      AdvisedDT: tmpClaimInfo[0][0].AdvisedDT,
      AdvisedDate: tmpClaimInfo[0][0].AdvisedDate,
    })
   
    console.log(tmpClaimInfo[0][0].DccOrDcm)
    setServiceType(tmpClaimInfo[0][0].DccOrDcm)

    setRiskAddress(tmpClaimInfo[0][0].IWAddress)
    setGoogleMapsCall(tmpClaimInfo[0][0].GoogleMapsCall)

    setLogData(tmpClaimInfo[1])
    setClaimrolesData(tmpClaimInfo[2])
    
    setRemindersData(tmpClaimInfo[3])

    // setdocfilesTileData(tmpClaimInfo[4])
    setAllDocsData(tmpClaimInfo[5])
    setDocfoldersData(tmpClaimInfo[6])

    setPhotosData(tmpClaimInfo[21])

    // setComplaintsData(tmpClaimInfo[7])
    // setAppointmentsData(tmpClaimInfo[8])

    setStepsData(tmpClaimInfo[9])
    // console.log(tmpClaimInfo[9])
  
    setDccSLAsData(tmpClaimInfo[22])
  
    setReferralsData(tmpClaimInfo[11])
    setServicecostsTileData(tmpClaimInfo[20])

    setRolePartnersData(tmpClaimInfo[12])
    setRolePartnerPersonsData(tmpClaimInfo[13])

    //Update the filtered state in hopes it renders on load and not refresh
    setFilteredLogs(tmpClaimInfo[1].slice(0, 20))

    setEstimsAmt(tmpClaimInfo[23][0].ApprEstimsAmt)
    setEstimsInvoicedAmt(tmpClaimInfo[23][0].InvoicedEstimsAmt)
    setEstimsPaidAmt(tmpClaimInfo[23][0].PaidEstimsAmt)
    setInvsAmt(tmpClaimInfo[23][0].ApprInvsAmt)
    setPaidInvsAmt(tmpClaimInfo[23][0].PaidInvsAmt)
    setBalanceInvsAmt(tmpClaimInfo[23][0].BalanceInvsAmt)

    localStorage.setItem('claimData', JSON.stringify(tmpClaimInfo[0]))
    localStorage.setItem('claimrolesData', JSON.stringify(tmpClaimInfo[2]))
    localStorage.setItem('partnerData', JSON.stringify(tmpClaimInfo[12]))
    localStorage.setItem('partnerPersonsData', JSON.stringify(tmpClaimInfo[13]))

    setDcmPeopleData(tmpClaimInfo[14])

    localStorage.setItem('clientPersonsData', JSON.stringify(tmpClaimInfo[15]))

    setEstimsData(tmpClaimInfo[16])
    setinvsData(tmpClaimInfo[17])

    setIsLoading(false)
  }

    //=======================================================================
  //  CLIENT PRODUCT DATA
  //=======================================================================
   
  const renderClientProducts = ( ) => {
    const records = JSON.parse(localStorage.getItem('clientProducts'));
    // console.log(records)
    return (
      <Select style={inputFields} showSearch placeholder={"Select the Carrier ..."} >
        {records.map((line, i) => <Option key={line.ProductID} value={line.InsurerID}>{line.Product}</Option>)}
    </Select>
    )
  };


  

  //=======================================================================
  //  CLAIMROLES DROPDOWN DATA
  //=======================================================================
  // const renderClaimRolesDropdownList = ( records ) => {
  //   return (
  //     <Select style={inputFields} showSearch >
  //       {records.map((line, i) => <Option key={i} value={line.ClaimRoleID}>
  //         {line.Person}, {line.Company} [{line.RoleTypeStr}]</Option>)}
  //   </Select>
  //   )
  // };

  // const renderClaimRolesPersonDropdownList = ( records ) => {
  //   return (
  //     <Select style={inputFields} showSearch labelInValue >
  //       {records.map((line, i) => <Option key={i} value={line.PersonID}>
  //         {line.Person}, {line.Company} [{line.RoleTypeStr}]</Option>)}
  //   </Select>
  //   )
  // };

  const renderDcmPeopleDropdownList = ( records ) => {
    return (
      <Select 
        style={inputFields} 
        showSearch 
        labelInValue
        onChange={setPersonIDValue}
        >
        {records.map((line, i) => <Option key={line.PersonID} value={line.PersonID}>
          {line.ShowName}</Option>)}
    </Select>
    )
  };

  const setPersonIDValue = (value) => {
    localStorage.setItem('personID', value.key)
  }


  const renderClaimRolesEmailList = ( records ) => {
    return (
      <Select showSearch mode="tags" allowClear style={{ width: '100%', color:'blue' }}
        placeholder="Select from the dropdown or type in an email address" >
        {records.map((line, i) => <Option key={i} value={line.email}>
          {line.email} [{line.Person}]</Option>)}
    </Select>
    )
  };

  const renderClaimRolesCellList = ( records ) => {
    return (
      <Select showSearch mode="tags" allowClear style={{ width: '100%', color:'blue' }}
        placeholder="Select from the dropdown or type in an email address" >
        {records.map((line, i) => <Option key={i} value={line.Mobile}>
          {line.Mobile} [{line.Person}]</Option>)}
    </Select>
    )
  };


  //=======================================================================
  //  SYSCODE DATA
  //=======================================================================
  const sysCodesData = require("../data/SysCodes.json");
  const sysCodes = sysCodesData[0];
  const renderSysCodes = ( codeType, label ) => {
    const records = sysCodes.filter( (sysCodes) => sysCodes.xsType === codeType)
    return (
      <Select required style={inputFields} showSearch placeholder={label}>
        <Option value=""></Option>)
        {records.map((line, i) => <Option key={i} value={line.xsCode}>{line.xsDescr}</Option>)}
    </Select>
    )
  };

 
  //=======================================================================
  //  STYLES
  //=======================================================================
  // TILE COLORS
  const white = '#ffffff'
  const lightBlue = '#F8F8FF'
  // const lightPurple = '#f4ecf7'
  const lightGreen = '#fbfcf4'
  const lightYellow = '#fef5e7' 
  // const lightOrange = '#fbe9e7'
  const lightGray = '#f2f4f4'
  // const lightSalmon = '#fdf2e9'
  const lightPink = '#fdf8f7'
  // const navy = 'navy'

  const bgColor = {
    white: {backgroundColor: white},
    lightGray: {backgroundColor: lightGray},
    lightYellow: {backgroundColor: lightYellow},
    lightBlue: {backgroundColor: lightBlue},
    lightPink: {backgroundColor: lightPink},
    lightGreen: {backgroundColor: lightGreen}
  };

  // STYLE CONSTANTS
  const dataStyles = {
    width: '90%',
    verticalAlign: 'top',
    marginLeft: 20,
    marginRight: 20,
    marginTop: 0,
    marginBottom: 20
  };
  
  const narrowDataStyles = {
    width: '100%',
    verticalAlign: 'top',
    marginLeft: 0,
    marginRight: 20,
    marginTop: -10,
    marginBottom: 20
  };

  const fontSize = 14
  // const smallFontSize = 12

  const bodyStyles = {
    fontSize:{fontSize},
    padding: 0,
    height: '140%',
    marginLeft:-20, marginRight:-20, marginTop:-20, marginBottom:-20
  };

  const textareaStyle = {
    marginBottom:10, 
    fontWeight: 'bold'
    // width:1122
  }

  const inputFields = { color:'blue' }

  const fieldsTogether = { marginBottom: 0 }
  const fieldsTogetherBold = { marginBottom: 0,  fontWeight:'bold' }
  const textareaStyleBold = { marginBottom:10, fontWeight: 'bold' }
  
  const notifySuccessStyle = {
    backgroundColor:'#C2FFB3',
    width: 600
  }
  const notifyFailStyle = {
      backgroundColor:'#FF7171',
      width: 600
  }


  //=======================================================================
  const searchBox = () => {
  //=======================================================================
    return (
      <div style={{fontSize:18}}>
        <Input style={inputFields} placeholder="Search ... [ClaimID]"
                  onInput={e => resetSearchString(e.target.value)}
                  allowClear
        />
      </div>
    )
  };

  const resetSearchString = (searchString) => {
    //Do this when length of string is 6 starting with "1"
    console.log("searchString", searchString)
    if ((searchString.length === 6) && !isNaN(searchString) && (searchString.substring(0, 1) === "1")) {
      console.log("claimID fulfilled", searchString)
      setClaimID(searchString)
      localStorage.setItem('claimId', (searchString))
      _getClaim()
  }
}


//=======================================================================
  //  claimIconsList
  //=======================================================================
  const iconStyle = {
    color: 'steelblue', 
    fontSize: 20, 
    width: 24
  }

  const claimIconsList = () => {
    return (
      <div style={{display:'inline-block', float:'right'}}>
        <span style={{cursor:'pointer', width:40}} >
          <Tooltip title="System Help">
            <a href='https://admin.davidcoreymedical.com/help' target='_blank'>
              <InfoOutlined style={iconStyle} />
            </a>
          </Tooltip>
        </span>
        <span style={{cursor:'pointer', width:40}}>
          <Tooltip title="Sign-out">
            <PoweroffOutlined onClick={() => signOut() } style={iconStyle} /><br/>
          </Tooltip>
        </span>
      </div> 
    )
  }


  //=======================================================================
  //  showLoggedIn
  //=======================================================================
  const showLoggedIn = () => {
    return (
      <div style={{color:'black', marginLeft:10, fontSize:16}}>{userName} is logged in&nbsp;</div>
    )
  }


  //=======================================================================
  //  claimHeader
  //=======================================================================
  const logoImgFile = "https://nstruc.s3.ap-southeast-2.amazonaws.com/logoDCM2.png"
  // const logoImgStyle = { width: '200px', height: '60px' };

  const claimHeader = () => {
    var version = 'DCM Connect ' + process.env.REACT_APP_VERSION
    if (!isLoading) {
      // let userId = localStorage.getItem('userEmail')
      return (
        <div style={{fontSize:18}}>
          <table style={{verticalAlign: 'top', width: '100%'}} border={0}>
            <thead>
              <tr style={{verticalAlign:'top'}}>
                <td style={{width:'25%', textAlign: 'left'}}>
                  <Link to='/' state={{referer:"ClaimHome"}}>
                    <Tooltip title={version}>
                      <Image
                        alt='David Corey medical'
                        preview={false}
                        src={logoImgFile}
                        className='logoImgStyle'
                      />
                    </Tooltip>
                  </Link>
                </td>
                <td style={{width:'50%', textAlign: 'center'}}>
                  <span style={itemStyle.headerTop}>{claimHdrData[0].ClaimRef}</span><br/>
                  <span style={itemStyle.headerBottom}>{claimHdrData[0].IWfullname}, {claimHdrData[0].IWAddress}</span>  
                </td>
                <td style={{width:'25%', textAlign: 'right', marginRight:10, fontSize:18}}>
                  {claimIconsList()}
                  {searchBox()}
                  {showLoggedIn()}
                </td>
              </tr>
            </thead>
          </table>
        </div>
      )
    }
  };


  //=======================================================================
  function signOut() {
    //=======================================================================
      // localStorage.removeItem('userEmail')
      console.log("SignOut Fired From ClaimHome")
      localStorage.clear()
      localStorage.clear()
      Auth.signOut()
        .catch(err => console.log('error signing out: ', err))
      navigate("/")
    }

      
  //=======================================================================
  //  renderClaimValue
  //=======================================================================
  const renderClaimValue = ( records, field ) => {
    if(field === "riskAddress") {
      return (
        <div>
            {records.map((line, i) => (
              <div key={i}>
                 {line.RiskAddress}
              </div>
              ))}
        </div>
      )
    }
    else if(field === "thisStep") {
      return (
        <div>
            {records.map((line, i) => (
              <div key={i}>
                 {line.ThisStepDescr}
              </div>
              ))}
        </div>
      )
    }
    else {
      return ( <div></div> )
    }
  };


//=======================================================================
  //  renderClaimDetails
  //=======================================================================
  const renderClaimDetails = ( records ) => {
    if (!isLoading) {
      return (
        <div style={dataStyles}>
          {records.map((line, i) => (
            <div key={i}>
              <table style={{verticalAlign: 'top', width: '100%', textAlign:"left"}} border={0}>
                <thead>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td style={{width:'60%'}}>
                      <span style={itemStyle.label}>Carrier / Referral</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.maroon)}>{line.ProdShortName}</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.red)}>{line.ReferralTypes}</span><br/>
                    </td>
                    <td style={{width:'40%'}}>
                      <span style={itemStyle.label}>ClaimNo / GClaimID</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.green)}>{line.ClaimNo}</span>&nbsp;
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.darkgray)}>{line.GClaimID}</span><br/>
                    </td>
                  </tr>
                </thead>
                <thead>
                  <tr><td colSpan={2}>&nbsp;</td></tr>
                </thead>
              </table>

              <table style={{verticalAlign: 'top', width: '100%', textAlign:"left"}} border={0}>
                <thead>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td style={{width:'65%'}}>
                      <span style={itemStyle.label}>Injured Worker</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.blue)}>{line.IWfullname}</span><br/>
                    </td>
                    <td style={{width:'35%'}}>
                      <span style={itemStyle.label}>Date of Birth / SSN</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.green)}>{line.IW_DOB}</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.black)}>{line.IW_SSN}</span>
                    </td>
                  </tr>
                </thead>
              </table>

              <table style={{verticalAlign: 'top', width: '100%', textAlign:"left"}} border={0}>
                <thead>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td style={{width:'50%'}}>
                      <span style={itemStyle.label}>CONTACTS</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.steelblue, itemStyle.phone)}>H:{line.IW_PhoneH}</span><br/>
                    </td>
                    <td style={{width:'50%'}}>
                      <span style={itemStyle.label}></span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.steelblue, itemStyle.phone)}>M:{line.IW_Cell}</span><br/>
                    </td>
                  </tr>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td colSpan={2}>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.steelblue)}>{line.IW_email}</span>
                    </td>
                  </tr>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td style={{width:'100%'}} colSpan={4}>
                      <span style={itemStyle.label}><br/>Preferred Communication</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.maroon)}>{line.CommunicPrefDescr}</span>
                    </td>
                  </tr>
                </thead>
                <thead>
                  <tr><td colSpan={2}>&nbsp;</td></tr>
                </thead>
              </table>

              <table style={{verticalAlign: 'top', width: '100%', textAlign:"left"}} border={0}>
                <thead>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td style={{width:'33%'}}>
                      <span style={itemStyle.label}>Injury</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.green)}>{line.InjuryDate}</span>
                    </td>
                    <td style={{width:'33%'}}>
                      <span style={itemStyle.label}>Discharge</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.green)}>{line.DischargeDate}</span>
                    </td>
                    <td style={{width:'33%'}}>
                      <span style={itemStyle.label}>Notified</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.green)}>{line.AdvisedDate}</span>
                    </td>
                  </tr>
                </thead>
              </table>

              <table style={{verticalAlign: 'top', width: '100%', textAlign:"left"}} border={0}>
                <thead>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td style={{width:'50%'}}>
                      <span style={itemStyle.label}>Assignment Type</span><br/>                     
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.black)}>{line.InstructTypeDescr}</span><br/>
                    </td>
                    <td style={{width:'50%'}}>
                      <span style={itemStyle.label}>ICD Code</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.black)}>{line.IW_ICDCode}</span>
                    </td>
                  </tr>
                </thead>
                <thead>
                  <tr><td colSpan={2}>&nbsp;</td></tr>
                </thead>
              </table>

              <table style={{verticalAlign: 'top', width: '100%', textAlign:"left"}} border={0}>
                <thead>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td colSpan={2} style={{width:'100%'}}>
                      <span style={itemStyle.label}>Diagnosis / Notes</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.green)}>{line.MED_Diagnosis}</span>&nbsp;
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.steelblue)}>{line.IW_Comments}</span>
                    </td>
                  </tr>
                </thead>
              </table>

              </div>
            ))}
        </div>
      )
    }
  };
   


  //=======================================================================
  //  EditClaimForm
  //=======================================================================
  const EditClaimForm = ({ visible, onCreate, onCancel }) => {
    // console.log(currEditClaim);
    const [form] = Form.useForm();
    return (
      <Modal
        onCreate={console.log("onCreate")}
        width={1200}
        open={isEditClaimFormVisible}
        title="Edit Claim Details"
        bodyStyle={{backgroundColor:'#f8f9f9'}}
        okText="Save" 
        cancelText="Cancel" 
        maskClosable={false}
        onCancel={() => {
          form.resetFields()
          setEditClaimFormVisible(false)
          }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              _setEditClaimDataItem(values)
              setEditClaimFormVisible(false)
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          initialValues={currEditClaim}
          form={form} name="EditClaim_form" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
          style={{backgroundColor:'#f8f9f9'}}
          // initialValues={currEditClaimData}
        >
          <Form.Item name='dcmInsID' label='Carrier' style={{fontWeight:'bold'}}>
            {renderClientProducts()}
          </Form.Item>

          <Form.Item label = "Carrier Claim Number" name="ClaimNo" style={{marginBottom:0, fontWeight:'bold'}}>
            <Input style={inputFields} placeholder="Carrier Claim Number" />
          </Form.Item>

          <Form.Item label="Injury/Surgery/Discharge/Notified Dates" style={{marginBottom:0, fontWeight:'bold'}}>
            <Form.Item name='MED_InjuryDT' style={{ display: 'inline-block', width: 'calc(25%)'}}>
              {/* <DatePicker placeholder='Date of Injury' style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }}/> */}
              <Input style={inputFields} placeholder="Date of Injury" /> 
            </Form.Item>
            <Form.Item name='SurgeryDT' style={{ display: 'inline-block', width: 'calc(25%)'}}>
              {/* <DatePicker placeholder='Date of Surgery' style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }}/> */}
              <Input style={inputFields} placeholder="Date of Surgery" /> 
            </Form.Item>
            <Form.Item name='DCH_DischargeDT' style={{ display: 'inline-block', width: 'calc(25%)'}}>
              {/* <DatePicker placeholder='Date of Discharge' style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }}/> */}
              <Input style={inputFields} placeholder="Date of Discharge" /> 
            </Form.Item>
            <Form.Item name='NotifiedDT' style={{ display: 'inline-block', width: 'calc(25%)'}}>
              {/* <DatePicker placeholder='Date DCC Notified' style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }}/> */}
              <Input placeholder="Date DCC Notified"/>
            </Form.Item>
          </Form.Item>

          <Form.Item label="Injured Worker Details" style={{marginBottom:0, fontWeight:'bold'}}>
            <Form.Item name="IW_Title" style={{ display: 'inline-block', width: 'calc(20%)', marginBottom:0 }}><Input style={inputFields} placeholder="Mr/Ms etc" /></Form.Item>
            <Form.Item name="IW_Given" style={{ display: 'inline-block', width: 'calc(40%)', marginBottom:0 }}><Input style={inputFields} placeholder="First name" /></Form.Item>
            <Form.Item name="IW_Surname" style={{ display: 'inline-block', width: 'calc(40%)', marginBottom:0 }}><Input style={inputFields} placeholder="Last name" /></Form.Item>
          </Form.Item>

          <Form.Item label="Contact Details" style={{marginBottom:0}}>
            <Form.Item name="IW_PhoneH" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="Phone (Home)" /> 
            </Form.Item>
            <Form.Item name="IW_Cell" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="Cell" /> 
            </Form.Item>
            <Form.Item name="IW_email" style={{ display: 'inline-block', width: 'calc(50%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="email" /> 
            </Form.Item>
          </Form.Item>

          <Form.Item label="Personal Details" style={{marginBottom:0}}>
            <Form.Item name="IW_DOB" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
              {/* <DatePicker placeholder='Date of Birth' style={{ display: 'inline-block', width: 'calc(100%)', marginBottom:0 }}/> */}
              <Input style={inputFields} placeholder="Date of Birth" /> 
            </Form.Item>
            <Form.Item name="IW_Gender" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
              {renderSysCodes("Gender", "Gender")}
            </Form.Item>
            <Form.Item name="IW_Height" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} > 
              <Input style={inputFields} placeholder="Height"/> 
            </Form.Item>
            <Form.Item name="IW_Weight" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} > 
              <Input style={inputFields} placeholder="Weight"/> 
            </Form.Item>
          </Form.Item>

          <Form.Item label="Preferences">
            <Form.Item name="IW_Preferred" style={{ display: 'inline-block', width: 'calc(50%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="Preferred name" /> 
            </Form.Item>
            <Form.Item name="IW_PrefLang" style={{ display: 'inline-block', width: 'calc(50%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="Preferred language"/>
            </Form.Item>
          </Form.Item>

          <Form.Item label="Home Address" style={{marginBottom:0, fontWeight:'bold'}}>
            <Form.Item name="IW_Address1" style={{marginBottom:0}}><Input style={inputFields} placeholder="Line 1"/></Form.Item>
            <Form.Item name="IW_Address2" style={{marginBottom:0}}><Input style={inputFields} placeholder="Line 2"/></Form.Item>
            <Form.Item name="IW_City" style={{ display: 'inline-block', width: 'calc(50%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="City/Town" /> 
            </Form.Item>
            <Form.Item name="IW_State" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="State"/>
            </Form.Item>
            <Form.Item name="IW_Zipcode" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} > 
              <Input style={inputFields} placeholder="Zipcode"/> 
            </Form.Item>
          </Form.Item>

          <Form.Item label="Jurisdiction State / ICD Codes" style={{marginBottom:0, fontWeight:'bold'}}>
            <Form.Item name="IW_Jurisdiction" style={{display: 'inline-block', width: 'calc(50%)', fontWeight:'bold', marginBottom:10}}>
              <Input style={inputFields} placeholder="Jurisdiction State"/> 
            </Form.Item>
            <Form.Item name='IW_ICDCode' style={{display: 'inline-block', width: 'calc(50%)', fontWeight:'bold', marginBottom:10 }}>
              <Input style={inputFields} placeholder="ICD Codes"/> 
            </Form.Item>
          </Form.Item>

          <Form.Item label="Hospital Details" style={{marginBottom:0, fontWeight:'bold'}}>
            <Form.Item name="IW_HospitalName" style={{ display: 'inline-block', width: 'calc(70%)', fontWeight:'bold', marginBottom:0 }} >
              <Input style={inputFields} placeholder="Hospital" /> 
            </Form.Item>
            <Form.Item name="IW_HospitalRoomNo" style={{ display: 'inline-block', width: 'calc(30%)', marginBottom:0}} >
              <Input style={inputFields} placeholder="Ward/Room No" /> 
            </Form.Item>
          </Form.Item>
          <Form.Item name="IW_DischargeCode" label="Discharge Status" placeholder="Discharge Status" >
              {renderSysCodes("DischargeStatus", "Discharge Status")}
          </Form.Item>

          <Form.Item label="Referral/Instruction Types" style={{marginBottom:0, fontWeight:'bold'}}>
            <Form.Item name="AdjusterRef" style={{display: 'inline-block', width: 'calc(50%)', fontWeight:'bold', marginBottom:0}}>
              {renderSysCodes("ProviderType", "Referral Type")}
            </Form.Item>
            <Form.Item name='InstructType' style={{display: 'inline-block', width: 'calc(50%)', fontWeight:'bold', marginBottom:10 }}>
              {renderSysCodes("InstructType", "Assignment Type")}
            </Form.Item>
          </Form.Item>

          <Form.Item label = "Diagnosis" name='MED_Diagnosis' style={textareaStyle}>
            <TextArea style={inputFields} rows={3}/>
          </Form.Item>

          <Form.Item label = "Instructions/Notes" name='IW_Comments' style={textareaStyle}>
            <TextArea style={inputFields} rows={3}/>
          </Form.Item>

          <Form.Item label="GClaimID (if applicable)" name='GClaimID' style={{fontWeight:'bold'}}>
            <Input style={inputFields} placeholder="GClaimID (if applicable)" />
          </Form.Item>

        </Form>

      </Modal>
    );
  };

  const onCreateEditClaimForm = (values, title) => {
    setIsLoading(true)
  };


  //=======================================================================
  //  _setEditClaimDataItem
  //=======================================================================
  const _setEditClaimDataItem = async (formData) => {
    var UserEmail = localStorage.getItem("userEmail")
    var claimId = localStorage.getItem("claimId")

    //Push the blob to API to run in background - setIsLoading NOT set
    // setIsLoading(true)

    await fetch(process.env.REACT_APP_BASE_URL + "setClaimV4", {
      body: JSON.stringify(
        {
          "action": "Update",
          "loginID": UserEmail,
          "claimID": claimId,
          
          "dcmGuId":'',
          "dcmLaId":0,
          "dcmInsId":formData.dcmInsID,

          "claimNo":formData.ClaimNo,
          "gClaimID":formData.GClaimID,
          "lossAdjRef":formData.AdjusterRef,
          "instructType":formData.InstructType,
          "notifiedDT":formData.NotifiedDT,

          "iw_Title":formData.IW_Title,
          "iw_Given":formData.IW_Given,
          "iw_Surname":formData.IW_Surname,
          "iw_PhoneH":formData.IW_PhoneH,
          "iw_Cell":formData.IW_Cell,
          "iw_Email":formData.IW_email,

          "iw_Dob":formData.IW_DOB,
          "iw_Gender":formData.IW_Gender,
          "iw_Height":formData.IW_Height,
          "iw_Weight":formData.IW_Weight,

          "iw_Preferred":formData.IW_Preferred,
          "iw_PrefLang":formData.IW_PrefLang,

          "iw_Address1":formData.IW_Address1,
          "iw_Address2":formData.IW_Address2,
          "iw_City":formData.IW_City,
          "iw_State":formData.IW_State,
          "iw_ZipCode":formData.IW_Zipcode,
          "iw_Jurisdiction":formData.IW_Jurisdiction,

          "med_InjuryDT":formData.MED_InjuryDT,
          "surgeryDT":formData.SurgeryDT,
          "dch_DischargeDT":formData.DCH_DischargeDT,

          "iw_HospitalName":formData.IW_HospitalName,
          "iw_HospitalRoomNo":formData.IW_HospitalRoomNo,
          "iw_DischargeCode":formData.IW_DischargeCode,
          "iw_RushStatCode":"",

          "iw_ICDCode":formData.IW_ICDCode,
          "med_Diagnosis":formData.MED_Diagnosis,
          "iw_Comments":formData.IW_Comments
        }
      ),
      headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
        "accesstoken": getUserToken()
      },
      method: "POST"
    })

    .then((response) => response.json())
    .then((responseJSON) => console.log("New Claim API response: ", responseJSON))
    .catch((error) => console.log("Network Error: " + error))
    .finally()

    _getClaim();

    console.log("Saved new claim data");
    // props.history.push('claimhome')
  }




//=======================================================================
//  _getClaimRoleData
//=======================================================================
const _getClaimRoleData = async (claimRoleID) => {
  var UserEmail = localStorage.getItem("userEmail")

  await fetch(process.env.REACT_APP_BASE_URL + "getClaimRole", {
      body: JSON.stringify(
        {
          "action": '',
          "loginID": UserEmail,
          "claimRoleID": claimRoleID.toString(),
        }
      ),
      headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
        "accesstoken": getUserToken()
      },
      method: "POST"
    })
    .then((response) => response.json())
    .then((responseJSON) => setCurrClaimRole(responseJSON.recordset[0]))
    .finally(setClaimRoleFormVisible(true))
  }
  
  
  const setEmptyClaimRole = () => {
    // console.log("setEmptyClaimRole");
    // console.log(currClaimRole);
    setCurrClaimRole({
      modifier: 'public',
      ClaimRoleID: "",
      claimRoleID: "",
      crType: "",
      crPartnerID: "",
      crPersonID: "",
      crNotes: ""
    });
  }

  //=======================================================================
  //  renderClaimRoles
  //=======================================================================
  const renderClaimRoles = ( records ) => {
    if (!isLoading) {
      return (
        <div style={dataStyles}>
          {records.map((line, i) => (
            <div key={i}>
            <table>
              <thead>
                <tr>
                    <td>
                      <span style={Object.assign({}, itemStyle.label, itemStyle.darkgray)}>{line.RoleTypeStr} </span><br/>
                      <Tooltip title={line.Notes}>
                        <span 
                          onClick={() => { 
                            setCurrEditClaimRole({
                              modifier: 'public',
                              ClaimRoleID: line.ClaimRoleID,
                              crType: line.crType,
                              crPartnerID: line.crPartnerID,
                              crPersonID: line.crPersonID,
                              crNotes: line.crNotes
                            });
                            console.log(currEditClaimRole);
                            _getClaimRoleData(line.ClaimRoleID)
                            // setClaimRoleFormVisible(true); 
                            }}
                          >
                            <EditOutlined style={{cursor:'pointer', fontSize:18, width:{iconWidth}}} /> 
                            <span style={Object.assign({}, itemStyle.basicLight, itemStyle.blue)}>{line.Person}</span>&nbsp;&nbsp;
                            <span style={Object.assign({}, itemStyle.basicLight, itemStyle.blue, itemStyle.phone)}>{line.Mobile}</span>&nbsp;&nbsp;

                            <span style={Object.assign({}, itemStyle.basicLight, itemStyle.black)}>{line.Company}</span>
                        </span>
                        &nbsp;&nbsp;
                        <span style={Object.assign({}, itemStyle.basicLight, itemStyle.green, itemStyle.phone)}>{line.Phones} </span>
                      </Tooltip>
                    </td>
                </tr>
              </thead>
            </table>
            </div>
            )
          )}
      </div>
      )
    }
  };


 


  //=======================================================================
  //  ClaimRoleForm
  //=======================================================================
  const ClaimRoleForm = ({ visible, onCreate, onCancel }) => {
    const [partnerPersonsData, setPartnerPersonsData] = useState(rolePartnerPersonsData)

    const [roleType, setRoleType] = useState('')

    console.log(currEditClaimRole.ClaimRoleID)

    //----------------------------------------------------------------------
    //  CLAIMROLE PARTNERS FOR GIVEN ROLETYPE
    const renderAllPartnersForType = ( newRoleType ) => {
      console.log("renderAllPartnersForType", newRoleType);
      var allPartnersData = [JSON.parse((localStorage.getItem('allPartnersData')))][0]
      // console.log(allPartnersData);

      const newRolePartnersData = allPartnersData.filter( (allPartnersData) => allPartnersData.PartnerType === newRoleType) 
      // console.log(newRolePartnersData);

      return (
        <Select 
          showSearch
          optionFilterProp="children"
          filterOption 
          placeholder={"Select the Organization ..."} 
          style={renderClaimRoleDropdowns} 
          onChange={selectPartnerID}
        >
          {newRolePartnersData.map((line, i) => <Option key={line.PartnerID} value={line.PartnerID}>{line.PartnerName}</Option>)}
        </Select>
      )
    }
    //----------------------------------------------------------------------

    // const halfWidthField = 300

    const renderClaimRoleDropdowns = { color:'blue' }

    //----------------------------------------------------------------------
    const renderPartnerNames = ( records ) => {
      // console.log(records);
      return (
        <Select 
          showSearch
          optionFilterProp="children"
          filterOption 
          placeholder={"Select the Organization ..."} 
          style={renderClaimRoleDropdowns} 
          onChange={selectPartnerID}
        >
          {records.map((line, i) => <Option key={line.PartnerID} value={line.PartnerID}>{line.PartnerName}</Option>)}
        </Select>
      )
    };
    //----------------------------------------------------------------------

    const selectPartnerID = (value) => {
      console.log("value", value)
      console.log("selectPartnerID", value.key)
      localStorage.setItem('selPartnerID', value.key)
    }

    //----------------------------------------------------------------------
    const renderPartnerPersons = ( records ) => {
      // console.log(records);
      return (
        <Select 
          showSearch 
          optionFilterProp="children"
          filterOption 
          placeholder={"Select an already-listed Person for this Organization ..."} 
          style={renderClaimRoleDropdowns} 
        >
          {records.map((line, i) => <Option key={line.PersonID} value={line.PersonID}>{line.LastName}, {line.FirstName}</Option>)}
        </Select>
      )
    };
    //----------------------------------------------------------------------

    
    //----------------------------------------------------------------------
    const setClaimRolesPersonsList = async ( newPartnerID, roleType ) => {
    //----------------------------------------------------------------------
      console.log(newPartnerID, roleType)

      if(!roleType) roleType = ""

      var UserEmail = localStorage.getItem("userEmail")
      var claimId = localStorage.getItem("claimId")

      await fetch(process.env.REACT_APP_BASE_URL + "listPersons", {
        body: JSON.stringify(
          {
            "action": '',
            "loginID": UserEmail,
            "partnerID": newPartnerID.toString(),
            "claimID": claimId,
            "roleType": roleType,
            "search": '',
          }
        ),
             headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
       "accesstoken": getUserToken()
      },
        method: "POST"
      })
      .then((response) => response.json())
      .then((responseJSON) => setPartnerPersonsData(responseJSON[0]) )

      return( renderPartnerPersons(partnerPersonsData) )
    }
    //----------------------------------------------------------------------


    //=======================================================================
    const resetClaimRoleSelectLists = (newRoleType, newPartnerID, newPersonID) => {
    //=======================================================================
    console.log( newRoleType )

    // let roleType
      let claimRoleID
      let partnerID
      let personID
  
      if(currClaimRole) {
        // roleType = currClaimRole.RoleType
        setRoleType(currClaimRole.RoleType)

        claimRoleID = currClaimRole.ClaimRoleID
        partnerID = currClaimRole.PartnerID
        personID = currClaimRole.PersonID
      }
  
      // console.log( newRoleType, newPartnerID, newPersonID, claimRoleID, partnerID, personID, roleType )
  
      if( newRoleType) { 
        console.log("newRoleType: ", newRoleType)
        
        setRoleType(newRoleType)
        console.log( newRoleType, roleType )

        renderAllPartnersForType( newRoleType )
      }
      if( newPartnerID) { 
        console.log("newPartnerID: ", newPartnerID, "ROLETYPE", roleType)
        setClaimRolesPersonsList( newPartnerID, roleType )
      }
      if( newPersonID) {
         console.log( "newPersonID", newPersonID )
      }

      // console.log( roleType )
    };


    //=======================================================================
    //  ClaimRole Form
    const [form] = Form.useForm();
    //=======================================================================
    // console.log(currClaimRole)
    // console.log(rolePartnersData)

    return (
      <Modal
        onCreate={console.log("ClaimRole Form Created")}
        width={800}
        open={isClaimRoleFormVisible}
        title="Add or Edit Claim Role Details"
        bodyStyle={{backgroundColor:'#f8f9f9'}}
        okText="Save" 
        cancelText="Cancel" 
        maskClosable={false}
        onCancel={() => {
          form.resetFields()
          setClaimRoleFormVisible(false)
          }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              _setClaimRoleDataItem(values)
              setClaimRoleFormVisible(false)
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form} 
          name="ClaimRole_form" 
          labelCol={{ span: 8 }} 
          wrapperCol={{ span: 16 }}
          style={{backgroundColor:'#f8f9f9'}}
          initialValues={currClaimRole}
          onValuesChange={(values) => { resetClaimRoleSelectLists( values.RoleTypeStr, values.PartnerID, values.PersonID ) }
          }
        >
          <Form.Item name='RoleTypeStr' label='Claim Role Type' style={{fontWeight:'normal'}}
                          rules={[{ required: true, message:"Please select a Role Type" }]}>
            {renderSysCodes("RoleType", "Select the Role Type")}
          </Form.Item>

          <Form.Item name='NewPartnerID' label='NOT YET IN CLAIM ROLES' style={{marginTop:30, marginBottom:10, fontWeight:'bold'}}>
            {renderAllPartnersForType(roleType)}
          </Form.Item>

          <Form.Item name='PA_Name' label='OR Add a new Organization' style={{marginBottom:0, fontWeight:'bold'}}>
            <Input placeholder="New Organization Name" style={{color:'blue', fontWeight:'normal'}} />
          </Form.Item>
          <Form.Item label="Address" style={{marginBottom:0}}>
            <Form.Item name="PA_Address1" style={{marginBottom:0}}><Input style={inputFields} placeholder="Line 1"/></Form.Item>
            <Form.Item name="PA_Address2" style={{marginBottom:0}}><Input style={inputFields} placeholder="Line 2"/></Form.Item>
            <Form.Item name="PA_City" style={{ display: 'inline-block', width: 'calc(50%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="City/Town" /> 
            </Form.Item>
            <Form.Item name="PA_State" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="State"/>
            </Form.Item>
            <Form.Item name="PA_Zipcode" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} > 
              <Input style={inputFields} placeholder="Zipcode"/> 
            </Form.Item>
          </Form.Item>
          <Form.Item label="Contact Details" style={{marginBottom:0}}>
            <Form.Item name="PA_PhoneBH" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="Phone (BH)" /> 
            </Form.Item>
            <Form.Item name="PA_PhoneAH" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="Phone (AH)" /> 
            </Form.Item>
            <Form.Item name="PA_email" style={{ display: 'inline-block', width: 'calc(50%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="Company email" /> 
            </Form.Item>
          </Form.Item>

          <Form.Item name='PartnerID' label='ALREADY IN CLAIM ROLES' style={{marginTop:30, marginBottom:0, fontWeight:'bold'}}>
            {renderPartnerNames(rolePartnersData)}
          </Form.Item>

          <Form.Item name='PersonID' label='Select the Person' style={{marginTop:10, marginBottom:10, fontWeight:'bold'}}>
            {renderPartnerPersons(partnerPersonsData)}
          </Form.Item>
          <Form.Item label="OR Add a new Person record" style={{marginBottom:0, fontWeight:'bold'}}>
            <Form.Item name="PP_Title" style={{ display: 'inline-block', width: 'calc(20%)', marginBottom:0 }}><Input style={inputFields} placeholder="Mr/Ms etc" /></Form.Item>
            <Form.Item name="PP_FirstName" style={{ display: 'inline-block', width: 'calc(40%)', marginBottom:0 }}><Input style={inputFields} placeholder="First name" /></Form.Item>
            <Form.Item name="PP_LastName" style={{ display: 'inline-block', width: 'calc(40%)', marginBottom:0 }}><Input style={inputFields} placeholder="Last name" /></Form.Item>
          </Form.Item>
          <Form.Item name="PP_JobTitle" label="Job Title" style={{ marginBottom:0 }} >
            <Input style={inputFields} placeholder="Job Title" /> 
          </Form.Item>
          <Form.Item label="Contact Details" style={{marginBottom:0}}>
            <Form.Item name="PP_PhoneW" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="Phone (Work)" /> 
            </Form.Item>
            <Form.Item name="PP_Cell" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="Cell" /> 
            </Form.Item>
            <Form.Item name="PP_email" style={{ display: 'inline-block', width: 'calc(50%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="email" /> 
            </Form.Item>
          </Form.Item>

          <Form.Item name="Notes" label='Notes' style={{marginTop:30, marginBottom:20, fontWeight:'bold'}}>
            <TextArea style={inputFields}  rows={2}/>
          </Form.Item>

          <Form.Item name='IsDeleted' valuePropName="checked" 
                        label={<label style={{color:'red'}}>DELETE THIS CLAIM ROLE RECORD?</label>}
                        style={{fontWeight:'bold', color:'red', marginTop:40}} >
            <Checkbox />
          </Form.Item>

        </Form>
      </Modal>
    );
  };

  const onCreateClaimRoleForm = (values, title) => {
    setIsLoading(true)
  };

  
  //=======================================================================
  //  _setClaimRoleDataItem
  //=======================================================================
  const _setClaimRoleDataItem = async (formData) => {
    var UserEmail = localStorage.getItem("userEmail")

    var claimID = claimHdrData[0].ClaimID.toString()

    let currAction = "Add";

    setIsLoading(false)

    console.log(currEditClaimRole.ClaimRoleID)

    var claimRoleID = currEditClaimRole.ClaimRoleID
    if(!claimRoleID) { claimRoleID = "" }
    console.log( claimRoleID )

    var selPartnerID = localStorage.getItem('selPartnerID')

    console.log(selPartnerID, formData.NewPartnerID)
    var partnerID = BWN(selPartnerID)
    if(partnerID === "") partnerID = BWN(formData.NewPartnerID)

    var personID = BWN(formData.PersonID)

    console.log(formData.RoleTypeStr)
    var crType = BWN(formData.RoleTypeStr)
    console.log(crType)

    var notes = ""
    if(formData.Notes) notes = formData.Notes

    var isDel = ""
    if(formData.IsDeleted) isDel = formData.IsDeleted

    //Push the blob to API to run in background - setIsLoading NOT set
    await fetch(process.env.REACT_APP_BASE_URL + "setClaimRole2", {
      body: JSON.stringify(
        {
          "action": currAction,
          "loginID": UserEmail,
          "claimRoleID": BWN(claimRoleID),
          "claimID": BWN(claimID),

          "crType": crType,
          "crPartnerID": BWN(partnerID),
          "crPersonID": BWN(personID),
          "crNotes": BWN(notes),

          "paName": BWN(formData.PA_Name),
          "paAddress1": BWN(formData.PA_Address1),
          "paAddress2": BWN(formData.PA_Address2),
          "paCity": BWN(formData.PA_City),
          "paState": BWN(formData.PA_State),
          "paZipcode": BWN(formData.PA_Zipcode),
          "paPhoneBH": BWN(formData.PA_PhoneBH),
          "paPhoneAH": BWN(formData.PA_PhoneAH),
          "paEmail": BWN(formData.PA_email),

          "ppTitle": BWN(formData.PP_Title),
          "ppFirstName": BWN(formData.PP_FirstName),
          "ppLastName": BWN(formData.PP_LastName),
          "ppJobTitle": BWN(formData.PP_JobTitle),
          "ppPhoneW": BWN(formData.PP_PhoneW),
          "ppMobile": BWN(formData.PP_Cell),
          "ppEmail": BWN(formData.PP_email),

          "isDeleted": BWN(isDel)
        }
      ),
           headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
       "accesstoken": getUserToken()
      },
      method: "POST"
    })
    .then((response) => response.json())
    .then((responseJSON) => console.log("Add/Edit Claim Role API response: ", responseJSON))
    .catch((error) => console.log("Network Error: " + error))
    .finally()

    _getClaim();

    console.log("Saved new Claim Role data");
  }

 

    //=======================================================================
  //  renderSteps
  //=======================================================================
  // const { Step } = Steps;

  //=======================================================================
  const _loadCurrStepData = ( record ) => {
  //=======================================================================
    var isExclude = false;
    if( BWN(record.StepExclude) !== "") isExclude = false;

    setCurrStepData(
        { 
          modifier: 'public',
          ClaimID: record.ClaimID,
          StepNotes: '',
          StepCompleteDT: record.StepEndsStr,
          StepCompleteTM: '',
          NotCompleteReason: '',
          NextStepDueDT: '',
          StepExcludeFromStats: isExclude,
          StepExcludeNotes: BWN(record.StepExclude),
          StepNotApplicable: record.StepIsNa
        }
      )
    };

    
  const showStep = ( stepDescr, stepEndDT, stepExclude, line ) => {
    return (
      <div>
        <span 
          onClick={() => { 
            setStep(stepDescr); 
            _loadCurrStepData(line);
            setEditStepFormVisible(true); 
          }}
          style={Object.assign({}, itemStyle.basicLight, itemStyle.blue, itemStyle.link)} >
            {stepDescr}
        </span>
        &nbsp;
        <span style={Object.assign({}, itemStyle.basic, itemStyle.green)}>{stepEndDT}</span>
        &nbsp;
        <span style={Object.assign({}, itemStyle.basic, itemStyle.red)}>{stepExclude}</span>
    </div>
    )
  };
  

  const renderSteps = ( records ) => {
    if (!isLoading) {
      let currStep = 1
      if(claimHdrData.length === 0) {
        currStep = 1
      }
      else {currStep = claimHdrData[0].ThisStepNo}
      
      return (
        <div>
          <span style={{marginLeft: 20, marginTop:-10, color:'blue', fontWeight:'bold'}}>STEPS</span><br/>
          <Steps direction="vertical" current={currStep} style={dataStyles}>
            {records.map((line, i) => (
              <item 
                key={i}
                title={<span style={Object.assign({}, itemStyle.list, itemStyle.gray)}>{line.StepStatus}</span>}
                description={showStep(line.ThisStep, line.StepEndsStr, line.StepExclude, line)}
                onClick= {() => setCurrStep(i)}
                />
            )
          )}
          </Steps>
        </div>
      )
    }
  };

  
  const renderSDCCSLAs = ( records ) => {
    // console.log(records)
    return (
      <div style={narrowDataStyles}>
          <table style={{width:'100%', marginBottom:0}} border={0}>
            <thead>
              <tr style={{width:'60%'}}>
                <td style={{width:'10%', textAlign:'center'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Days</span><br/>
                </td>
                <td style={{width:'50%', textAlign:'center'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>SLA Item</span><br/>
                </td>
              </tr>
            </thead>
          </table>

          {records.map((line, i) => (
            <div key={i}>
              <table style={{width:'100%'}} border={0}>
                <thead>
                  <tr style={{width:'60%', verticalAlign:'bottom', borderBottomStyle:'dotted', borderBottomWidth:0.5}}>                     
                    <td style={{width:'10%', textAlign:'center'}}>
                      <span style={Object.assign({}, itemStyle.upperRow, itemStyle.red)}>{line.SLAResultStr}</span><br/>
                    </td>
                    <td style={{width:'50%', textAlign:'left'}}>
                      <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.blue)}>{line.SLADescr}</span><br/>
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          ))}
      </div>
    )
  };


  //=======================================================================
  const renderEstims = ( records ) => {
  //=======================================================================
    // console.log(records)
    return (
      <div style={narrowDataStyles}>
          <table style={{width:'106%', marginLeft:-10, marginBottom:0}} border={0}>
            <thead>
              <tr style={{width:'100%'}}>
                <td style={{width:'22%'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Submitted</span><br/>
                </td>
                <td style={{width:'22%', textAlign:'center'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Approved</span><br/>
                </td>
                <td style={{width:'10%', textAlign:'center'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Billed?</span><br/>
                </td>
                <td style={{width:'10%', textAlign:'center'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Paid?</span><br/>
                </td>
                <td style={{width:'36%'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Ref</span><br/>
                </td>
              </tr>
            </thead>
          </table>

          {records.map((line, i) => (
            <div key={i}>
              <table style={{width:'106%', marginLeft:-10, }} border={0}>
                <thead>
                  <Tooltip title={line.Notes}>
                    <tr style={{width:'100%', verticalAlign:'bottom', borderBottomStyle:'dotted', borderBottomWidth:0.5}}>
                      <td style={{width:'22%'}}>
                        <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.green)}>{line.SubmitDate}</span><br/>
                      </td>
                      <td style={{width:'22%', textAlign:'right'}}>
                        <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.maroon)}>{line.ApproveAmt}</span><br/>
                      </td>
                      <td style={{width:'10%', textAlign:'center'}}>
                        <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.blue)}>{line.IsInvoicedYN}</span><br/>
                      </td>
                      <td style={{width:'10%', textAlign:'center'}}>
                        <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.green)}>{line.IsPaidYN}</span><br/>
                      </td>
                      <td style={{width:'36%'}}>
                          <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.black)}>{line.Ref}</span><br/>
                      </td>
                    </tr>
                  </Tooltip>
                </thead>
              </table>
            </div>
          ))}
          <div>
            <span style={Object.assign({}, itemStyle.upperRow, itemStyle.maroon)}>Approved: {estimsAmt}</span><br/>
            <span style={Object.assign({}, itemStyle.upperRow, itemStyle.blue)}>Invoiced: {estimsInvoicedAmt}</span><br/>
            <span style={Object.assign({}, itemStyle.upperRow, itemStyle.green)}>Paid: {estimsPaidAmt}</span><br/>
          </div>
      </div>
    )
  };


    //=======================================================================
    const renderInvoices = ( records ) => {
    //=======================================================================
    // console.log(records)
    return (
      <div style={narrowDataStyles}>
          <table style={{width:'106%', marginLeft:-10, marginBottom:0}} border={0}>
          <thead>
              <tr style={{width:'100%'}}>
                {/* <td style={{width:'35%', textAlign:'left'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Billed By</span><br/>
                </td> */}
                <td style={{width:'22%'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Invoiced</span><br/>
                </td>
                <td style={{width:'22%', textAlign:'center'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Amount</span><br/>
                </td>
                <td style={{width:'10%', textAlign:'center'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Paid?</span><br/>
                </td>
                <td style={{width:'40%'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Invoice No</span><br/>
                </td>
              </tr>
            </thead>
          </table>

          {records.map((line, i) => (
            <div key={i}>
              <table style={{width:'106%', marginLeft:-10, }} border={0}>
              <thead>
                  <Tooltip title={line.Notes}>
                    <tr style={{width:'100%', verticalAlign:'bottom', borderBottomStyle:'dotted', borderBottomWidth:0.5}}>
                      {/* <td style={{width:'35%', textAlign:'left'}}>
                        <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.red)}>{line.Payee}</span><br/>
                      </td> */}
                      <td style={{width:'22%'}}>
                        <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.green)}>{line.InvoiceDate}</span><br/>
                      </td>
                      <td style={{width:'22%', textAlign:'center'}}>
                        <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.blue)}>{line.BaseAmt}</span><br/>
                      </td>
                      <td style={{width:'10%', textAlign:'center'}}>
                        <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.green)}>{line.IsPaidYN}</span><br/>
                      </td>
                      <td style={{width:'40%'}}>
                          <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.black)}>{line.InvoiceNo}</span><br/>
                      </td>
                    </tr>
                    </Tooltip>
                  <tr style={{verticalAlign:'top'}}>
                    <td colSpan='3'>
                      {/* {renderServiceItems(line.ServiceItems)} */}
                    </td>                    
                  </tr>
                </thead>
              </table>
            </div>
          ))}
          <div>
            <span style={Object.assign({}, itemStyle.upperRow, itemStyle.blue)}>Invoiced: {invsAmt}</span><br/>
            <span style={Object.assign({}, itemStyle.upperRow, itemStyle.green)}>Paid: {paidInvsAmt}</span><br/>
            <span style={Object.assign({}, itemStyle.upperRow, itemStyle.red)}>Balance Due: {balanceInvsAmt}</span><br/>
          </div>
      </div>
    )
  };

  

  //=======================================================================
  //  EditStepForm
  //=======================================================================
  const EditStepForm = ({ visible, onCreate, onCancel }) => {

    const [form] = Form.useForm();
    const formTitle = "Editing Step: " + step;

    const timeFormat = 'HH:mm';

    const getItem = (showItem, height) => {
      return {
        visibility: showItem ? 'visible' : 'hidden', 
        height: showItem ? height : 0,
        marginTop: showItem ? 12 : 0,
        color: 'red'
        }
    }

    const selectDate = (value) => {
      console.log("selectDate", value)
    }

    return (
      <Modal
        onCreate={console.log("Edit Step Form Created")}
        width={1200}
        open={isEditStepFormVisible}
        title={formTitle}
        bodyStyle={{backgroundColor:'#f8f9f9'}}
        okText="Save" 
        cancelText="Cancel" 
        maskClosable={false}
        onCancel={() => {
          form.resetFields()
          setEditStepFormVisible(false)
          }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              _setEditStepDataItem(values)
              setEditStepFormVisible(false)
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form} name="EditStep_form" 
          labelCol={{ span: 8 }} 
          wrapperCol={{ span: 16 }}
          style={{backgroundColor:'#f8f9f9'}}
          initialValues={currStepData}
        >
          <div style={getItem((step === "DC07 Estimate Submit" || step === "DC08 Estimate Review" || step === "DC09 Carrier Auth"), 60)}>
            <div onClick={() => { navigate("/estimates"); }} style={Object.assign({}, itemStyle.link, itemStyle.red, itemStyle.bold)}>Jump to Estimates page here here ...</div>
          </div>

          <div style={getItem((step === "17 Claim Cancelled"), 20)}>
            <div style={Object.assign({}, itemStyle.link, itemStyle.red, itemStyle.bold)}>Jump to Claim Details Edit page here here ...</div>
          </div>


          <Form.Item name='StepNotes' label="STEP IS NOW COMPLETE" style={{marginTop:20, marginBottom:0, fontWeight:'bold', color:'green'}}>
            <TextArea style={inputFields} placeholder='Notes relating to the Step completion' rows={2}/>
          </Form.Item>

          <Tooltip title="Default is current date/time">

            <Form.Item label="Date/Time Step was completed" style={{marginTop:10, marginBottom:0, fontWeight:'bold'}}>

              <Form.Item name="StepCompleteDT" style={{ display: 'inline-block', width: 'calc(25%)', fontWeight:'bold', color:'green' }} >
                <Input />
              </Form.Item>
              <Form.Item name="StepCompleteTM" style={{ display: 'inline-block', width: 'calc(25%)', fontWeight:'bold', color:'green' }} >
                <Input />
              </Form.Item>

            </Form.Item>

          </Tooltip>

          <Form.Item label="STEP IS NOT YET COMPLETE?" style={{marginTop:20, marginBottom:0, fontWeight:'bold', color:'red'}}>
            <TextArea style={inputFields} name='NotCompleteReason' placeholder='Reason not yet complete' rows={2}/>
          </Form.Item>
          <Form.Item name="NextStepDueDT" label="Expected Date Step expected to be complete" style={{marginBottom:20}}><DatePicker /></Form.Item>


          <Form.Item name='StepExcludeFromStats' valuePropName="checked" label="EXCLUDE STEP FROM SLA's?" style={{marginBottom:0, fontWeight:'bold'}} ><Checkbox /></Form.Item>
          <Form.Item name='StepExcludeNotes'label="Exclusion Reasons" style={{marginTop:0}} >
            <TextArea style={{color:'blue', marginTop:0}}  placeholder='Reason(s) this Step is to be excluded from SLA measures' rows={2}/>
          </Form.Item>

          <Form.Item name='StepNotApplicable' valuePropName="checked" label="STEP IS NOT APPLICABLE?" style={{marginTop:20}} ><Checkbox /></Form.Item>

        </Form>
      </Modal>
    );
  };


  const onCreateEditStepForm = (values, title) => {
      setIsLoading(true)
    };



  //=======================================================================
  //  _setEditStepDataItem
  //=======================================================================
  const _setEditStepDataItem = async (formData) => {
    var UserEmail = localStorage.getItem("userEmail")
    let currAction = "SetStepAction"
    let claimID = claimHdrData[0].ClaimID.toString()

    let stepNA = "false"
    if(formData.StepNotApplicable) stepNA = "true"
    console.log(formData.StepNotApplicable, stepNA)

    let stepExcl = "false"
    if(formData.StepExcludeFromStats) stepExcl = "true"
    console.log(formData.StepExcludeFromStats, stepExcl)

    console.log(formData.StepCompleteDT)
    console.log(formData.StepCompleteTM)
    var stepCompleteDT = ""
    var stepCompleteTM = ""
    if(formData.StepCompleteDT) {stepCompleteDT = convertNiceDateToSql(formData.StepCompleteDT)}
    if(formData.StepCompleteTM) {stepCompleteTM = formData.StepCompleteTM}
    console.log(stepCompleteDT, stepCompleteTM)

    if((stepCompleteDT !== "") && (stepCompleteTM !== "")) stepCompleteDT += " " + stepCompleteTM
    console.log(stepCompleteDT)

    //Push the blob to API to run in background - setIsLoading NOT set
    setIsLoading(false)
    await fetch(process.env.REACT_APP_BASE_URL + "setStepAction", {
      body: JSON.stringify(
        {
          "action": currAction,
          "loginID": UserEmail,
          "claimID": claimID,

          "stepActionType": step,
          
          "completeDate": stepCompleteDT,

          "visitDueDT": '',
          "planStartDT": '',
          "planFinishDT": '',
          "actualStarDT": '',
          "actualFinishDT": '',
          
          "notes": formData.StepNotes,

          "notApplicable": stepNA,
          
          "excludeFromStats": stepExcl,
          "excludeCode": '',
          "excludeNotes": formData.StepExcludeNotes
        }
      ),
           headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
        "accesstoken": getUserToken()
      },
      method: "POST"
    })
    .then((response) => response.json())
    .then((responseJSON) => console.log("Add/Edit Claim Role API response: ", responseJSON))
    .catch((error) => console.log("Network Error: " + error))
    .finally()

    _getClaim();

    console.log("Saved new Step data");
  }


  //=======================================================================
  const renderLatestImages = ( records ) => {
  //=======================================================================
  return (
      <div style={{marginLeft:-20, marginRight:-20, marginTop:-16, marginBottom:-20}}>
        {records.map((line, i) => (
          <div key={i} style={{width:'100px', float:'left'}}>
            <Image
              preview={false}
              src={line.ThumbURL}
              style={{width:'100px', height:'100px'}}
            />
          </div>
        ))}
      </div>
    )};

  
  //=======================================================================
  const renderDocFolders = ( records ) => {
  //=======================================================================
  return (
        <div>
          <table style={{width: '100%'}} border={0}>
            <thead>
              <tr>
                  <td style={{width:'10%', textAlign: 'center'}}>
                    <span style={Object.assign({}, itemStyle.label, itemStyle.gray)}>Files</span>
                  </td>
                  <td style={{width:'2%'}}>
                    &nbsp;
                  </td>
                  <td style={{width:'80%'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.gray)}>Folder Name</span>
                  </td>
                </tr>
              </thead>
            {records.map((line, i) => (
                <thead key={i}>
                  <tr>
                      <td style={{width:'10%', textAlign: 'center'}}>
                        <span style={Object.assign({}, itemStyle.list, itemStyle.blue)}>{line.FilesCount}</span>
                      </td>
                      <td style={{width:'2%'}}>
                        &nbsp;
                      </td>
                      <td style={{width:'80%'}}>
                        <span style={Object.assign({}, itemStyle.list, itemStyle.black)}>{line.FolderName}</span>
                      </td>
                  </tr>
                </thead>
            ))}
          </table>
      </div>
      )
    };
  

      

  //=======================================================================
  //  Split and format the ServiceItems data to show in the Service Referrals table
  //=======================================================================
    // const renderServiceLine = ( serviceLineStr ) => {
    //   // console.log(serviceLineStr)
    //   var items = serviceLineStr.split("~")
      
    //   return (
    //     <div style={{verticalAlign:'top', fontWeight:'normal'}}>
    //       <span style={{marginLeft:10, fontSize:12, color:'green', textAlign:'left'}}>{items[2]}</span>&nbsp;&nbsp;
    //       <span style={{fontSize:12, color:'darkgray', textAlign:'left'}}>{items[1]}</span>&nbsp;&nbsp;
    //       <span style={{fontSize:12, color:'steelblue', textAlign:'left'}}>{items[0]}</span>
    //     </div>
    //   )
    // }

  // const renderServiceItems = ( serviceItemsStr ) => {
  //   // console.log(serviceItemsStr)

  //   if(!serviceItemsStr) serviceItemsStr = ""
  //     var lines = serviceItemsStr.split("|")
  //     // console.log(lines)
  
  //     return (
  //       <div style={{verticalAlign:'top', fontWeight:'normal'}}>
  //         {lines.map((line, i) => (
  //           <div key={i}>
  //             {renderServiceLine(line)}
  //           </div>
  //           )
  //         )}
  //       </div>      
  //     )
  //   };


  // =======================================================================
  //  renderServiceCosts
  // =======================================================================
  const renderServiceCosts = ( records ) => {
    // console.log(records)
    return (
      <div style={dataStyles}>
          <table style={{width:'100%', marginBottom:0}} border={0}>
            <thead>
              <tr style={{width:'100%'}}>
                <td style={{width:'20%', textAlign:'left'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Type</span><br/>
                </td>
                <td style={{width:'16%'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Date</span><br/>
                </td>
                <td style={{width:'20%', textAlign:'center'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Amount</span><br/>
                </td>
                <td style={{width:'40%'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Provider</span><br/>
                </td>
              </tr>
            </thead>
          </table>

          {records.map((line, i) => (
            <div key={i}>
              <table style={{width:'100%'}} border={0}>
                <thead>
                  <tr style={{width:'100%', verticalAlign:'bottom', borderBottomStyle:'dotted', borderBottomWidth:0.5}}>
                    <td style={{width:'20%', textAlign:'left'}}>
                      <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.red)}>{line.ServiceType}</span><br/>
                    </td>
                    <td style={{width:'16%'}}>
                      <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.green)}>{line.CostDate}</span><br/>
                    </td>
                    <td style={{width:'20%', textAlign:'center'}}>
                      <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.red)}>{line.GrossCharges}</span><br/>
                    </td>
                    <td style={{width:'40%'}}>
                      <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.black)}>{line.ServiceProvider}</span><br/>
                    </td>
                  </tr>
                  <tr style={{verticalAlign:'top'}}>
                    <td colSpan='4'>
                      {/* {renderServiceItems(line.ServiceItems)} */}
                    </td>                    
                  </tr>
                </thead>
              </table>
            </div>
          ))}
      </div>
    )
  };


  // =======================================================================
  //  renderReferrals
  // =======================================================================
  const renderReferrals = ( records ) => {
    // console.log(records)
    return (
      <div style={dataStyles}>
          <table style={{width:'100%', marginBottom:0}} border={0}>
            <thead>
              <tr style={{width:'100%'}}>
                <td style={{width:'15%', textAlign:'left'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Type</span><br/>
                </td>
                <td style={{width:'45%', textAlign:'center'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Service</span><br/>
                </td>
                <td style={{width:'30%'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Provider</span><br/>
                </td>
              </tr>
            </thead>
          </table>

          {records.map((line, i) => (
            <div key={i}>
              <table style={{width:'100%'}} border={0}>
                <thead>
                  <tr style={{width:'100%', verticalAlign:'bottom', borderBottomStyle:'dotted', borderBottomWidth:0.5}}>
                    <td style={{width:'15%', textAlign:'left'}}>
                      <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.red)}>{line.ServiceType}</span><br/><br/>
                    </td>
                    <td style={{width:'45%', textAlign:'left'}}>
                      <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.blue)}>{line.AdditInfo}</span><br/>
                      <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.green)}>{line.Notes}</span><br/>
                    </td>
                    <td style={{width:'30%'}}>
                      <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.black)}>{line.ServiceProvider}</span><br/>
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          ))}
      </div>
    )
  };


  //=======================================================================
  //  LOG FILTERS AND ICONS
  //=======================================================================
  const filterLogs = ( recType ) => {
    switch (recType) {
      case "Show the Reminders":
        setFilteredLogs(logData.filter(type  => type.RecType.includes('Reminder')).slice(0, 20))
        break;
      
      case "Show the Logs":
        setFilteredLogs(logData.filter(type  => type.RecType.includes('Log')).slice(0, 20))
        break;
      
      case "Show the Uploads":
        setFilteredLogs(logData.filter(type  => type.RecType.includes('Upload')).slice(0, 20))
        break;
      
      case "Remove Log Filters":
        setFilteredLogs(logData.filter(type  => type.RecType.includes('')).slice(0, 20))
        break;

      case "Show the emails":
        setFilteredLogs(logData.filter(type  => type.RecType.includes('email')).slice(0, 20))
        break;

      case "Show the SMSs":
        setFilteredLogs(logData.filter(type  => type.RecType.includes('SMS')).slice(0, 20))
        break;

      default:
    }

  };

  // const addRecord = ( recType ) => {
  //   console.log("Add a", recType);
  // };

  // const iconFontSize = 28;
  const iconWidth = 40;
  

  //=======================================================================
  //  ICON LINKS
  //=======================================================================
  const renderIconLink = ( iconType, iconTooltip ) => {
    switch(iconType) {
      case 'Edit': 
        return (
          <div><Tooltip title={iconTooltip}><EditOutlined style={{fontSize:28, width:{iconWidth}}} /></Tooltip></div>
          );

      case 'UserAdd': 
        return (
          <div><Tooltip title={iconTooltip}><UserAddOutlined style={{fontSize:28, width:{iconWidth}}} /></Tooltip></div>
          );
  
      case 'Alert': 
        return (
          <div><Tooltip title={iconTooltip}><AlertOutlined style={{fontSize:28, width:{iconWidth}}} /></Tooltip></div>
          );
  
      case 'addclaimlogsbytype': 
        return ( 
          <div style={{display:'inline-block'}}>
            <span style={{cursor:'pointer', width:40}} onClick={() => { _sendEmail(); }}>
              <Tooltip title="Send an email"><MailOutlined style={{fontSize:28, width:40}} /></Tooltip>
            </span>
            <span style={{cursor:'pointer', width:40}} onClick={() => { _sendSMS(); }}>
              <Tooltip title="Send a SMS"><MessageOutlined style={{fontSize:28, width:40}} /></Tooltip>
            </span>
            <span style={{cursor:'pointer', width:40}} onClick={() => { setEditReminderFormVisible(true); }}>
              <Tooltip title="Add a Reminder"><AlertOutlined style={{fontSize:28, width:40}} /></Tooltip>
            </span>
            <span style={{cursor:'pointer', width:40}} onClick={() => { setEditLogFormVisible(true); }}>
              <Tooltip title="Add a Log record"><DatabaseOutlined style={{fontSize:28, width:40}} /></Tooltip>
            </span>
          </div> 
        );

      default:

    }
  };


  const goToLink = ( itemLink ) => {
    switch(itemLink) {
      case "editclaim": { setEditClaimFormVisible(true); break; }

      case "addclaimrole": { setEmptyClaimRole(); setClaimRoleFormVisible(true); break; }
  
      case "addreminder": { setEditReminderFormVisible(true); break; }
  
      case "referrals": { navigate("/referrals"); break; }
      case "costslist": { navigate("/costslist"); break; }
  
      case "estimates": { navigate("/estimates"); break; }
      case "invoices": { navigate("/invoices"); break; }

      case "docslist": { navigate("/docslist"); break; }
      case "docimages": { navigate("/docimages"); break; }
    
      case "claimlogs": { console.log("6"); navigate("/claimlogs"); break; }
      case "claimreminders": { console.log("7"); navigate("/claimreminders"); break; }

      default:
    }  
  };


  const renderFilterIconLink = ( icon, tooltip ) => {
    return(
      <div style={{float:'left', fontSize:28, cursor:'pointer'}} onClick={() => { filterLogs(tooltip); }}>
        <Tooltip title={tooltip}>{icon}</Tooltip>
        &nbsp;&nbsp;
      </div>
    )
  };


  const renderTileHeaderTitleLink = ( title, titleLink, canJump ) => {
    // console.log(title, titleLink, canJump)

    if (titleLink === "" || !canJump) {
      return(
        <div>
          <span>{title}</span>
        </div>
      )
    }
    else
    {
      return(
        <div>
          <span style={{color: 'blue', textDecoration:'underline'}}>{title}</span>
        </div>
      )
    }
  };


  //=======================================================================
  //  renderTileHeader
  //=======================================================================
  const renderTileHeader = ( title, titleLink, canJump, titleTooltip,  iconType, iconLink, iconTooltip )   => {

    if (iconLink === "") {
      return (
        <div>
          <table style={{width:'100%'}} border={0}>
            <thead>
              <tr>
                <td style={{width:'100%'}}>
                  <span style={{cursor:'pointer', width:40}} onClick={() => { goToLink(titleLink); }}>
                    <Tooltip title={titleTooltip}>
                      {renderTileHeaderTitleLink(title, titleLink, canJump)}
                    </Tooltip>
                  </span>
                </td>
              </tr>
            </thead>
          </table>
        </div>
      )
    }
    else
    {
      if (iconLink === "addclaimlogsbytype") {
        return (
          <div>
            <table style={{width:'100%'}} border={0}>
              <thead>
                <tr>
                  <td style={{width:'60%'}}>
                    <span style={{cursor:'pointer', width:40}} onClick={() => { goToLink(titleLink); }}>
                      <Tooltip title={titleTooltip}>
                        {renderTileHeaderTitleLink(title, titleLink, canJump)}
                      </Tooltip>
                    </span>
                  </td>
                  <td style={{width:'40%'}}>
                    <div style={{display:'inline-block'}}>
                      <span style={{cursor:'pointer', width:40}} onClick={() => { _sendEmail(); }}>
                        <Tooltip title="Send an email"><MailOutlined style={{fontSize:28, width:40}} /></Tooltip>
                      </span>
                      <span style={{cursor:'pointer', width:40}} onClick={() => { _sendSMS(); }}>
                        <Tooltip title="Send a SMS"><MessageOutlined style={{fontSize:28, width:40}} /></Tooltip>
                      </span>
                      <span style={{cursor:'pointer', width:40}} onClick={() => { setEditReminderFormVisible(true); }}>
                        <Tooltip title="Add a Reminder"><AlertOutlined style={{fontSize:28, width:40}} /></Tooltip>
                      </span>
                      <span style={{cursor:'pointer', width:40}} onClick={() => { setEditLogFormVisible(true); }}>
                        <Tooltip title="Add a Log record"><DatabaseOutlined style={{fontSize:28, width:40}} /></Tooltip>
                      </span>
                    </div> 
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        )
      }
      else
      { 
        return (
          <div>
            <table style={{width:'100%'}} border={0}>
              <thead>
                <tr>
                  <td style={{width:'55%'}}>
                    <span style={{cursor:'pointer', width:40}} onClick={() => { goToLink(titleLink); }}>
                      <Tooltip title={titleTooltip}>
                        {renderTileHeaderTitleLink(title, titleLink, canJump)}
                      </Tooltip>
                    </span>
                  </td>
                  <td style={{width:'45%'}}>
                    <span style={{cursor:'pointer', width:40}} onClick={() => { goToLink(iconLink); }}>
                      {renderIconLink(iconType, iconTooltip)}
                    </span>
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        )
      }
    }
  };


  //=======================================================================
  //  SEARCH LOGS
  //=======================================================================
  const searchBoxLogs = () => {
    // console.log(logData)
    return (
      <div style={Object.assign({}, itemStyle.search, itemStyle.left)}>
        <Input style={inputFields} placeholder="Search ..." allowClear
                  onInput={e => setFilteredLogs(logData.filter(type  => type.LogSearch.toLowerCase().includes(e.target.value.toLowerCase())))}
                  onPressEnter={() => onSearchLogs()}
        />
      </div>
    )
  };

  const onSearchLogs = () => {
    // console.log(searchLogs)
    // setLogData(logData.filter(type  => type.LogSearch.toLowerCase().includes(searchLogs.toLowerCase())))
  };

  // const showItem = ( item ) => {
  //   switch(item) {
  //     case 'thisStep': return <span>{claimHdrData.thisStep}</span>;
  //     case 'riskAddress': return <span>{claimHdrData.RiskAddress}</span>;
  //     default: return <span></span>;
  //   }
  // };
 

  //=======================================================================
  //  renderLogs
  //=======================================================================
  const renderLogs = ( records, type, search ) => {
    if (!isLoading) {
      return (
        <div style={dataStyles}>
          <div style={{display:'inline-block', marginBottom:0}}>
            {renderFilterIconLink(<MailOutlined/>, "Show the emails")}
            {renderFilterIconLink(<MessageOutlined/>, "Show the SMSs")}
            {renderFilterIconLink(<AlertOutlined/>, "Show the Reminders")}
            {renderFilterIconLink(<DatabaseOutlined/>, "Show the Logs")}
            {renderFilterIconLink(<UploadOutlined/>, "Show the Uploads")}
            {searchBoxLogs()}
            {renderFilterIconLink(<CloseOutlined/>, "Remove Log Filters")}
          </div>
          <div>
            {records.map((line, i) => (
              <div key={i}>
              <table>
                <thead>
                  <tr style={{height:20, verticalAlign:'top'}}>
                      <td style={{width:'100%'}}>
                        <Tooltip title={line.LogText}>
                          <span 
                            onClick={() => { 
                              console.log("clicked Log", line.RecType, line.RecID);
                              switch(line.recType) {
                                case "Log":
                                  console.log("Cannot edit a Claim Log");
                                  break;
                                case "Reminder":
                                  setEditReminderFormVisible(true);
                                  break;
                                default:
                              }
                            }}
                            style={Object.assign({}, itemStyle.list, itemStyle.red, itemStyle.link)} >
                              {line.RecType}
                          </span>

                          <span style={Object.assign({}, itemStyle.list, itemStyle.maroon)}>{line.LogDateStr}</span>&nbsp;
                          <span style={Object.assign({}, itemStyle.list, itemStyle.blue)}>{line.LogTypeDescr}</span>&nbsp;
                          <span style={Object.assign({}, itemStyle.list, itemStyle.black)}>{line.LogShort}</span>&nbsp;
                          <span style={Object.assign({}, itemStyle.list, itemStyle.green)}>{line.LastChangedBy}, {line.RecordedDateStr}</span>
                        </Tooltip>                          
                        <br/><br/>
                      </td>
                  </tr>
                </thead>
              </table>
              </div>
              )
            )}
          </div>
      </div>
      )
    }
  };


  //=======================================================================
  //  EditLogForm
  //=======================================================================
  const EditLogForm = ({ visible, onCreate, onCancel }) => {
    const [form] = Form.useForm();
    const formTitle = "Add a Claim Log"

    // const format = 'yyyy-MM-DD HH:mm';

    return (
      <Modal
        onCreate={console.log("Edit Log Form Created")}
        width={800}
        open={isEditLogFormVisible}
        title={formTitle}
        bodyStyle={{backgroundColor:'#f8f9f9'}}
        okText="Save" 
        cancelText="Cancel" 
        maskClosable={false}
        onCancel={() => {
          form.resetFields()
          setEditLogFormVisible(false)
          }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              _setEditLogDataItem(values)
              setEditLogFormVisible(false)
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form} name="EditLog_form" 
          labelCol={{ span: 8 }} 
          wrapperCol={{ span: 16 }}
          style={{backgroundColor:'#f8f9f9'}}
          // initialValues={}
        >
          <Form.Item name='LogType' label='Log Type' style={{marginBottom:20}}>{renderSysCodes("LogType", "Log Type")}</Form.Item>

          <Form.Item name='LogRef' label='Reference' style={{marginBottom:20}}><Input /></Form.Item>

          <Form.Item name='LogText' label="Notes/text" style={{marginTop:20, marginBottom:0}}>
            <TextArea style={inputFields}  placeholder='Notes/text for the Claim Log' rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const onCreateEditLogForm = (values, title) => {
    setIsLoading(true)
  };


  //=======================================================================
  //  _setEditLogDataItem
  //=======================================================================
  const _setEditLogDataItem = async (formData) => {
    var UserEmail = localStorage.getItem("userEmail")

    let currAction = "AddLog";
    setIsLoading(false)

    //Push the blob to API to run in background - setIsLoading NOT set
    await fetch(process.env.REACT_APP_BASE_URL + "setClaimLog", {
      body: JSON.stringify(
        {
          "action": currAction,
          "loginID": UserEmail,
          "claimID": claimHdrData[0].ClaimID.toString(),
          "logType": formData.LogType,
          "logSubType": '',
          "logRef": formData.LogRef,
          "entityType": 'CL',
          "entityID": claimHdrData[0].ClaimID.toString(),
          "logDate": formData.LogDate,
          "logText": formData.LogText
        }
      ),
           headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
        "accesstoken": getUserToken()
      },
      method: "POST"
    })
    .then((response) => response.json())
    .then((responseJSON) => console.log("Add/Edit Claim Log API response: ", responseJSON))
    .catch((error) => console.log("Network Error: " + error))
    .finally()

    _getClaim();

    console.log("Saved new Step data");
  }


  //=======================================================================
  //  renderReminders
  //=======================================================================
  const renderReminders = ( records ) => {
    if (!isLoading) {
      return (
        <div style={dataStyles}>
          <br/>
          {records.map((line, i) => (
            <div key={i}>
            <table>
              <thead>
                <tr>
                    <td>
                      <div 
                        style={{cursor:'pointer', float:'left'}} 
                        onClick={() => { _dismissReminderDataItem(line.ReminderID); }}>
                        <Tooltip title={dismissReminderText(line.ReminderID)}>
                          <CheckCircleOutlined style={{fontSize:20, marginTop:0, marginRight:4, width:{iconWidth}}} />
                        </Tooltip>
                      </div>
                      <span style={Object.assign({}, itemStyle.list, itemStyle.red)}>{line.OverdueStatus}</span>
                      <span style={Object.assign({}, itemStyle.list, itemStyle.amber)}>{line.DueNowStatus}</span>&nbsp;
                      <span style={Object.assign({}, itemStyle.list, itemStyle.black)}>{line.ClaimReminderDetail}</span>&nbsp;
                      <span style={Object.assign({}, itemStyle.list, itemStyle.green)}>{line.PersonName}, {line.FromDate}</span><br/><br/>
                    </td>
                </tr>
              </thead>
            </table>
            </div>
            )
          )}
      </div>
      )
    }
  };
//_dismissReminderDataItem

  // const editReminderText = ( reminderID ) => {
  //   return ( "Edit Reminder" + reminderID );
  // };

  // const editReminder = ( reminderID ) => {
  //   console.log( "Edit Reminder" + reminderID );
  // };

  const dismissReminderText = ( reminderID ) => {
    return ( "Dismiss Reminder" + reminderID );
  };


  //=======================================================================
  //  EditReminderForm
  //=======================================================================
  const EditReminderForm = ({ visible, onCreate, onCancel }) => {
    const [form] = Form.useForm();
    const formTitle = "Add or Edit a Reminder"

    // const format = 'yyyy-MM-dd HH:mm';

    return (
      <Modal
        onCreate={console.log("Edit Reminder Form Created")}
        width={800}
        open={isEditReminderFormVisible}
        title={formTitle}
        bodyStyle={{backgroundColor:'#f8f9f9'}}
        okText="Save" 
        cancelText="Cancel" 
        maskClosable={false}
        onCancel={() => {
          form.resetFields()
          setEditReminderFormVisible(false)
          }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              _setEditReminderDataItem(values)
              setEditReminderFormVisible(false)
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form} name="EditReminder_form" 
          labelCol={{ span: 8 }} 
          wrapperCol={{ span: 16 }}
          style={{backgroundColor:'#f8f9f9'}}
          // initialValues={}
        >
          <Form.Item name='RemindPersonID' label='Person to Remind' style={{marginBottom:20}}>{renderDcmPeopleDropdownList(dcmPeopleData)}</Form.Item>

          <Form.Item name='ReminderType' label='Reminder Type' style={{marginBottom:20}}>{renderSysCodes("ReminderType", "Reminder Type")}</Form.Item>

          <Tooltip title="Default is current date/time">
            <Form.Item name="FromDate" label="Date/Time to start reminding"><DatePicker showTime/></Form.Item>
          </Tooltip>

          <Form.Item name='Urgency' label='Urgency' style={{marginBottom:20}}>{renderSysCodes("UrgencyLevel", "Urgency Level")}</Form.Item>

          <Form.Item name='Notes' label="Notes" style={{marginTop:20, marginBottom:0}}>
            <TextArea style={inputFields}  placeholder='Notes relating to the reminder' rows={4} />
          </Form.Item>

          <Form.Item name='IsDismissed' valuePropName="checked" label="DISMISS THIS REMINDER?" style={{marginBottom:20}} ><Checkbox /></Form.Item>
        </Form>
      </Modal>
    );
  };

  const onCreateEditReminderForm = (values, title) => {
    setIsLoading(true)
  };


  //=======================================================================
  //  _setEditReminderDataItem
  //=======================================================================
  const _setEditReminderDataItem = async (formData) => {
    var UserEmail = localStorage.getItem("userEmail")
    var login = UserEmail

    let currAction = "AddReminder";
    setIsLoading(false)

    let isDismissed = "false"
    if(formData.IsDismissed) isDismissed = "true"
    console.log(formData.IsDismissed, isDismissed)

    var remindPersonID = localStorage.getItem('personID')
    if(!remindPersonID) remindPersonID = ""
    // console.log(personID)

    //Push the blob to API to run in background - setIsLoading NOT set
    await fetch(process.env.REACT_APP_BASE_URL + "setReminder", {
      body: JSON.stringify(
        {
          "action": currAction,
          "loginID": login,
          "claimID": claimHdrData[0].ClaimID.toString(),
          "reminderID": '',
          "remindPersonID": remindPersonID.toString(),
          "reminderType": formData.ReminderType,
          "fromDate": formData.FromDate,
          "urgency": formData.Urgency,
          "notes": formData.Notes,
          "isDismissed": isDismissed
        }
      ),
           headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
       "accesstoken": getUserToken()
      },
      method: "POST"
    })
    .then((response) => response.json())
    .then((responseJSON) => console.log("Add/Edit Reminder API response: ", responseJSON))
    .catch((error) => console.log("Network Error: " + error))
    .finally()

    _getClaim();

    console.log("Saved new Reminder data");
  }


  //=======================================================================
  //  _dismissReminderDataItem
  //=======================================================================
  const _dismissReminderDataItem = async ( reminderID) => {
    var UserEmail = localStorage.getItem("userEmail")
    var login = UserEmail

    let currAction = "Dismiss";

    let isDismissed = "true"

    //Push the blob to API to run in background - setIsLoading NOT set
    await fetch(process.env.REACT_APP_BASE_URL + "setReminder", {
      body: JSON.stringify(
        {
          "action": currAction,
          "loginID": login,
          "claimID": claimHdrData[0].ClaimID.toString(),
          "reminderID": reminderID.toString(),
          "remindPersonID": "",
          "reminderType": "",
          "fromDate": "",
          "urgency": "",
          "notes": "",
          "isDismissed": isDismissed
        }
      ),
           headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
       "accesstoken": getUserToken()
      },
      method: "POST"
    })
    .then((response) => response.json())
    .then((responseJSON) => console.log("Add/Edit Reminder API response: ", responseJSON))
    .catch((error) => console.log("Network Error: " + error))
    .finally()

    _getClaim();

    console.log("Dismissed Reminder ", reminderID);
  }


  //=======================================================================
  //  renderDocFiles
  //=======================================================================
  // const renderDocFiles = ( records ) => {
  //   if (!isLoading) {
  //     return (
  //       <div style={dataStyles}>
          
  //         {records.map((line, i) => (
  //           <div key={i}>
  //           <table>
  //             <thead>
  //               <tr>
  //                 <td style={{width:'96%'}}>
  //                   <span style={{color:'gray', fontSize:12, verticalAlign:'bottom'}}>{line.FolderName}<br/></span>
  //                   <a href={line.FileURL} target='_docshow'>
  //                     <span style={{color:'blue', fontSize:16, verticalAlign:'top'}}>{line.FileName}</span><br/>
  //                   </a>
  //                 </td>
  //               </tr>
  //             </thead>
  //           </table>
  //           </div>
  //         ))}
  //     </div>
  //     )
  //   }
  // };



  const renderAttachsEmailList = ( records ) => {
    return (
      <Select mode="multiple" allowClear style={{ width: '100%', }}
        placeholder="Select from the dropdown" >
        {records.map((line, i) => <Option key={i} value={line.FileRefURL}>
          {line.FileRef}</Option>)}
    </Select>
    )
  };


  



  //=======================================================================
  //  renderLatestImages
  //=======================================================================
  // const renderLatestImages = ( records ) => {
  //   return (
  //     <div>
  //       {records.map((line, i) => (
  //         <div key={i} style={{width:'100px', float:'left'}}>
  //           <Image
  //             preview={false}
  //             src={line.ThumbURL}
  //             style={{width:'100px', height:'100px'}}
  //           />
  //         </div>
  //       ))}
  //     </div>
  //   )};

   
  //=======================================================================
  //  renderDocFolders
  //=======================================================================
    // const renderDocFolders = ( records ) => {
    //   return (
    //     <div>
    //       <table style={{width: '100%'}} border={0}>
    //         <thead>
    //           <tr>
    // <td style={{width:'10%', textAlign: 'center'}}>
    //   <span style={Object.assign({}, itemStyle.label, itemStyle.gray)}>Files</span>
    // </td>
    // <td style={{width:'2%'}}>
    //   &nbsp;
    // </td>
    // <td style={{width:'80%'}}>
    // <span style={Object.assign({}, itemStyle.label, itemStyle.gray)}>Folder Name</span>
    // </td>
    // //             </tr>
    // //           </thead>
    // //         {records.map((line, i) => (
    // //             <thead key={i}>
    // <tr>
    //     <td style={{width:'10%', textAlign: 'center'}}>
    //       <span style={Object.assign({}, itemStyle.list, itemStyle.blue)}>{line.DocsCount}</span>
    //     </td>
    //     <td style={{width:'2%'}}>
    //       &nbsp;
    //     </td>
    //     <td style={{width:'80%'}}>
    //       <span style={Object.assign({}, itemStyle.list, itemStyle.black)}>{line.FolderName}</span>
    //     </td>
    // </tr>
    //             </thead>
    //         ))}
    //       </table>
    //   </div>
    //   )
    // };


  //=======================================================================
  //  _sendEmail
  //=======================================================================
  const _sendEmail = () => {
    setEmailFormVisible(true)
    console.log({isEmailFormVisible});
    return (
      <div>
        Send email ...
      </div>
    )
  };
  

  //=======================================================================
  const EmailForm = (visible, onCreate, onCancel) => {
    //=======================================================================
    const [form] = Form.useForm();
  
    // var ClaimID = claimHdrData[0].ClaimID
    var ClaimRef = claimHdrData[0].ClaimRef
    var UserEmail = localStorage.getItem("userEmail")
  
    var initValues = {
      emFrom: UserEmail,
      emBCC: [UserEmail],
      emSubject: ClaimRef
    }
  
    return (
        <Modal
          onCreate={console.log("onSendEmail")}
          width={1200}
          open={isEmailFormVisible}
          title="Send an email"
          bodyStyle={{backgroundColor:'#f8f9f9'}}
          okText="Send now" 
          cancelText="Cancel" 
          maskClosable={false}
          onCancel={() => {
            form.resetFields()
            setEmailFormVisible(false)
            }}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                _setEmail(values)
                setEmailFormVisible(false)
              })
              .catch((info) => {
                console.log('Email Form Validate Failed:', info);
              });
          }}
        >
          <Form
            initialValues={initValues}
            form={form} name="Email_form" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
            style={{backgroundColor:'#f8f9f9'}}
          >
            <Form.Item name='emFrom' label='From' style={{fontWeight:'bold'}}>
              <Input style={inputFields} placeholder="From"/>
            </Form.Item>
  
            <Form.Item name='emTo' required label='To' style={{marginBottom:20, fontWeight:'bold'}}>{renderClaimRolesEmailList(claimrolesData)}</Form.Item>
  
            <Form.Item name='emCC' label='cc' style={{marginBottom:20, fontWeight:'bold'}}>{renderClaimRolesEmailList(claimrolesData)}</Form.Item>
  
            <Form.Item name='emBCC' label='bcc' style={{marginBottom:20, fontWeight:'bold'}}>{renderClaimRolesEmailList(claimrolesData)}</Form.Item>
  
            <Form.Item name='emAttachments' label='Attachments' style={{marginBottom:20, fontWeight:'bold'}}>{renderAttachsEmailList(allDocsData)}</Form.Item>
  
            <Form.Item name='emSubject' required label = "Subject" style={{marginBottom:20, fontWeight:'bold'}} >
              <Input style={{fontWeight:'bold', color:'red'}} />
            </Form.Item>
  
            <Form.Item name='emBody' label = "Body" style={textareaStyle}>
              <TextArea style={inputFields}   rows={12}/>
            </Form.Item>
  
          </Form>
  
        </Modal>
      );
    };
  
  
    // function MyComponent() {
    //   const [items, setItems] = React.useState([{ id: 0, description: 'Old Item' }])
    
    //   const loadMoreItems = () => {
    //     setItems([...items, { id: 1, description: 'New Item' }])
    //   }
    
    //   return (
    //     <>
    //       {items.map((item) => (
    //         <div key={item.id} value={item.description}>
    //           <p>{item.description}</p>
    //         </div>
    //       ))}
    //       <button onClick={loadMoreItems}>Load more items</button>
    //     </>
    //   )
    // }


    //=======================================================================
    //  _setEmail
    //=======================================================================
    const BWU = ( inStr ) => {
      if( !inStr ) {
        return []
      }
      else {
        return inStr
      }    
    }
    
    // const FlattenThis = ( inObj ) => {
    //   var thisItem = inObj.split("|")
    //   return(thisItem)
    // }

    const FlattenAttachments = ( inObj ) => {
      var noAttachs = []
      if(!inObj) return noAttachs

      var allItems = []      
      // var rebuildList = inObj.map((item, index) => {
      //   var thisItem = FlattenThis(item)
      //   allItems.push(thisItem)
      // })
      return(allItems)
    }
  
    const _setEmail = async (formData) => {
      var UserEmail = localStorage.getItem("userEmail")

      localStorage.setItem('claimId', ClaimID)
      console.log("_setEmail", ClaimID, BWU(formData.emTo), BWU(formData.emCC))
      console.log(FlattenAttachments( formData.emAttachments ))

      let tmpResult
  
      setIsLoading(true)
      await fetch(process.env.REACT_APP_BASE_URL + "sendEmailOAuth", {
        body: JSON.stringify(
          {
            "action": 'Send',
            "emailMessageID": '',
            "loginID": UserEmail,
            "claimID": ClaimID.toString(),        
            "emailType": 'DS',
            "emailStatus": 'PE',                 
            "emailFrom": formData.emFrom,
            "emailTo": BWU(formData.emTo),
            "emailCC": BWU(formData.emCC),
            "emailBCC": BWU(formData.emBCC),
            "emailSubject": BWU(formData.emSubject),      
            "emailBody": BWU(formData.emBody),
            "attachments": FlattenAttachments(formData.emAttachments),
            "sentDate": '',
            "receivedDate": '',                 
            "hTMLBodyYN": '' 
          }
        ),
             headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
       "accesstoken": getUserToken()
      },
        method: "POST"
      })
      .then((response) => response.json())
      .then((responseJSON) => tmpResult=responseJSON)
      console.log(tmpResult)
      setIsLoading(false)

      setIsLoading(false)
      // setCurrEditClaimData('');
      // _getClaim();

      console.log("email has been sent");
    }
  
    
  //=======================================================================
  //  _sendSMS
  //=======================================================================
  const _sendSMS = () => {
    setSmsFormVisible(true)
    console.log({isSmsFormVisible});
    return (
      <div>
        Send SMS ...
      </div>
    )
  };
  

  //=======================================================================
  const SMSForm = (visible, onCreate, onCancel) => {
  //=======================================================================
  const [form] = Form.useForm();

  // var ClaimID = claimHdrData[0].ClaimID
  var ClaimRef = claimHdrData[0].ClaimRef
  var UserEmail = localStorage.getItem("userEmail")

  var initValues = {
    emFrom: UserEmail,
    emBCC: [UserEmail],
    emSubject: ClaimRef
  }

  return (
      <Modal
        onCreate={console.log("onSendSMS")}
        width={1200}
        open={isSmsFormVisible}
        title="Send a SMS"
        bodyStyle={{backgroundColor:'#f8f9f9'}}
        okText="Send now" 
        cancelText="Cancel" 
        maskClosable={false}
        onCancel={() => {
          form.resetFields()
          setSmsFormVisible(false)
          }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              _setSMS(values)
              setSmsFormVisible(false)
            })
            .catch((info) => {
              console.log('SMS Form Validate Failed:', info);
            });
        }}
      >
        <Form
          initialValues={initValues}
          form={form} name="SMS_form" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
          style={{backgroundColor:'#f8f9f9'}}
        >
          <Form.Item name='emTo' required label='To' style={{marginBottom:20, fontWeight:'bold'}}>{renderClaimRolesCellList(claimrolesData)}</Form.Item>

          <Form.Item name='emBody' label = "Message" style={textareaStyle}>
            <TextArea style={inputFields}   rows={12}/>
          </Form.Item>

        </Form>

      </Modal>
    );
  };


  //=======================================================================
  //  _setSMS
  //=======================================================================
  const _setSMS = async (formData) => {
    localStorage.setItem('claimId', ClaimID)

    let toNo = formData.emTo[0]  
    console.log("_setSMS", ClaimID, toNo)

    toNo = toNo.replaceAll(" ", "")
    toNo = toNo.replaceAll("(", "")
    toNo = toNo.replaceAll(")", "")
    toNo = toNo.replaceAll("-", "")
    toNo = toNo.replaceAll("+1", "")
    toNo = "+1" + toNo
    console.log(toNo)

    let tmpResult

    setIsLoading(true)
    await fetch(process.env.REACT_APP_BASE_URL + "sendSMS", {
      body: JSON.stringify(
        {
          "smsBody": formData.emBody,       
          "to": formData.emTo 
        }
      ),
           headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
       "accesstoken": getUserToken()
      },
      method: "POST"
    })
    .then((response) => response.text())
    .then((responseJSON) => tmpResult=responseJSON)
    console.log(tmpResult)
    
    if (tmpResult === "Done") {
      setIsLoading(false)
      notification.open({
        message: formData.emSubject,
        description: 'The SMS has been sent.',
        className: 'custom-class',
        duration: 0,
        style: notifySuccessStyle,
      })
      // _getUserInfo()
    }
    else {
      setIsLoading(false)
      notification.open({
        message: 'SMS Set Failed',
        description:"Sorry, there has been a technical problem and the SMS has not been sent.",
        className: 'custom-class',
        duration: 0,
        style: notifyFailStyle,
      })
    }
    setIsLoading(false)
    _getClaim();
    // console.log("SMS has been sent");
  }


  //=======================================================================
  //  dcmPositions and dcmClaimTiles
  //=======================================================================
  // Setup for HD: good on 1920x1080 at 80% zoom, OK at 100% zoom
  const [dcmPositions, setDcmPositions] = React.useState([
    { col: 1, colSpan: 3, rowSpan: 5, order: 1 },
    { col: 1, colSpan: 3, rowSpan: 3, order: 2 },

    { col: 4, colSpan: 2, rowSpan: 2, order: 3 },
    { col: 4, colSpan: 2, rowSpan: 2, order: 4 },
    { col: 4, colSpan: 2, rowSpan: 4, order: 5 },

    { col: 6, colSpan: 3, rowSpan: 4, order: 6 },
    { col: 6, colSpan: 3, rowSpan: 4, order: 7 },

    { col: 9, colSpan: 4, rowSpan: 8, order: 8 }
  ]);

  let dcmClaimTiles = [
    { header:<div>{renderTileHeader("Claim Details", "editclaim", true, "Edit the Claim Details",  
				                            "Edit", "editclaim", "Edit the Claim Details")}</div>, 
      body: <div style={Object.assign({}, bodyStyles, bgColor.white)}>{renderClaimDetails( claimHdrData )}</div> },    

    { header:<div>{riskAddress}</div>,
      body:<iframe src={googleMapsCall} style={{height:'100%', width:'120%', padding: 0, height: '120%',  marginLeft:-20, marginRight:-20, marginTop:-20, marginBottom:-20}} ></iframe>},

    { header:<div>{renderTileHeader("Actions Due", "claimreminders", false, "View and Edit the full Claim Reminders List",  
                                    "Alert", "addreminder", "Add a Reminder")}</div>, 
      body: <div style={Object.assign({}, bodyStyles, bgColor.lightYellow)}>{renderReminders( remindersData )}</div> },

    { header:<div>{renderTileHeader("Claim Roles", "", "",  false, "UserAdd", "addclaimrole", "Add a Claim Role")}</div>, 
      body: <div style={Object.assign({}, bodyStyles, bgColor.lightPink)}>{renderClaimRoles( claimrolesData )}</div> },


    { header:<div>{renderTileHeader("Document Folders", "docslist", true, "Go to Documents", "")}</div>, 
      body: <div>{renderDocFolders( docfoldersData )}</div> },       

    { header:<div>{renderTileHeader("Referrals", "referrals", true, "View and Edit the full Referrals List",  
                                    "", "", "")}</div>, 
      body: <div style={Object.assign({}, bodyStyles, bgColor.white)}>{renderReferrals( referralsData )}</div> },

    { header:<div>{renderTileHeader("Service Costs", "costslist", true, "View and Edit the full Service Costs List",  
      "", "", "")}</div>, 
    body: <div style={Object.assign({}, bodyStyles, bgColor.white)}>{renderServiceCosts( servicecostsTileData )}</div> },

{ header:<div>{renderTileHeader("Claim Log", "claimlogs", true, "View and Edit the full Claim Logs List",  
				                            "addclaimlogsbytype", "addclaimlogsbytype", "")}</div>, 
      body: <div style={Object.assign({}, bodyStyles, bgColor.lightBlue)}>{renderLogs( filteredLogs, "claimlogs", "", "" )}</div> }
  ];


  //=======================================================================
  //  dccClaimPositions and dccClaimTiles
  //=======================================================================
  // Setup for HD: good on 1920x1080 at 80% zoom, OK at 100% zoom
  const [dccPositions, setDccPositions] = React.useState([
    { col: 1, colSpan: 2, rowSpan: 2, order: 1 },
    { col: 1, colSpan: 2, rowSpan: 1, order: 2 },

    { col: 3, colSpan: 2, rowSpan: 4, order: 3 },

    { col: 5, colSpan: 2, rowSpan: 1, order: 4 },
    { col: 5, colSpan: 2, rowSpan: 1, order: 5 },
    { col: 5, colSpan: 2, rowSpan: 2, order: 6 },

    { col: 7, colSpan: 2, rowSpan: 1, order: 7 },
    { col: 7, colSpan: 2, rowSpan: 2, order: 8 },

    { col: 9, colSpan: 4, rowSpan: 1, order: 9 },
    { col: 9, colSpan: 4, rowSpan: 3, order: 10 }
  ]);
  
  let dccClaimTiles = [
    { header:<div>{renderTileHeader("Claim Details", "editclaim", true, "Edit the Claim Details", "Edit", "editclaim", "Edit the Claim Details")}</div>, 
      body: <div style={Object.assign({}, bodyStyles, bgColor.white)}>{renderClaimDetails( claimHdrData )}</div> },

    { header:<div>{riskAddress}</div>,
      body:<iframe src={googleMapsCall} style={{height:'100%', width:'120%', padding: 0, height: '120%',  marginLeft:-20, marginRight:-20, marginTop:-20, marginBottom:-20}} ></iframe> },
  

    { header:<div>{renderTileHeader("Steps", renderClaimValue( claimHdrData, "thisStep"), false, "", "")}</div>, 
      body: 
        <div style={Object.assign({}, bodyStyles, bgColor.white)}>
          {renderSDCCSLAs( dccSLAsData )}
          {renderSteps( stepsData )}
        </div> },


    { header:<div>{renderTileHeader("Claim Roles", "", false, "",  "UserAdd", "addclaimrole", "Add a Claim Role")}</div>, 
      body: 
        <div style={Object.assign({}, bodyStyles, bgColor.lightPink)}>
          {renderClaimRoles( claimrolesData )}
        </div> },

    { header:<div>{renderTileHeader("Estimates", "estimates", true, "Go to Estimates List", "", "", "")}</div>, 
      body: <div>{renderEstims( estimsData )}</div> },  

    { header:<div>{renderTileHeader("Invoices", "invoices", true, "Go to Invoices List", "")}</div>, 
      body: <div>{renderInvoices( invsData )}</div> },

    { header:<div>{renderTileHeader("Actions Due", "claimreminders", true, "View and Edit the full Claim Reminders List",  
        "Alert", "addreminder", "Add a Reminder")}</div>, 
      body: <div style={Object.assign({}, bodyStyles, bgColor.lightYellow)}>{renderReminders( remindersData )}</div> },
  
      { header:<div>{renderTileHeader("Document Folders", "docslist", true, "Go to Documents", "")}</div>, 
      body: <div>{renderDocFolders( docfoldersData )}</div> },  
  

    { header:<div>{renderTileHeader("Latest Images", "docimages", true, "Go to Images", "")}</div>, 
      body:<div>{renderLatestImages( photosData )}</div> },

    { header:<div>{renderTileHeader("Claim Log", "claimlogs", true, "View and Edit the full Claim Logs List",  
        "addclaimlogsbytype", "addclaimlogsbytype", "")}</div>, 
      body: <div style={Object.assign({}, bodyStyles, bgColor.lightBlue)}>{renderLogs( filteredLogs, "claimlogs", "", "" )}</div> }

  ];

  const handleReposition = (e) => {
    console.log(serviceType, e.value);
    if(serviceType === "DCC") {
      setDccPositions(e.value);
    }
    else {
      setDcmPositions(e.value);
    }
  };


  // { header:<div>{renderTileHeader("Times SLAs", renderClaimValue( claimHdrData, ""), false, "", "")}</div>, 
  // body: <div style={Object.assign({}, bodyStyles, bgColor.white)}>{renderSDCCSLAs( dccSLAsData )}</div> },


  //======================================================================================
  //  MAIN RENDER
  //======================================================================================
  // const [positionsData, setPositionsData] = useState([]);
  // const [claimTiles, setClaimTiles] = useState([]);

  if (isLoading === true) {
    return (
      <h3>Loading Data...</h3>
    )
  }
  else {
    console.log(serviceType)
    let tileRows = 12
    let tileCols = 12
    let claimTiles = dcmClaimTiles
    let positions = dcmPositions
    if(serviceType === "DCC") {
      tileRows = 12
      tileCols = 12
      claimTiles = dccClaimTiles
      positions = dccPositions
    }    

    return (
      <div>
        <div>{claimHeader()}</div>

        <TileLayout
          autoFlow='column'
          columnAlign="justifyUsingWidth"
          rows={tileRows}
          columns={tileCols}
          gap={{ rows: 10, columns: 10, }}
          items={claimTiles}
          positions={positions}
          onReposition={handleReposition}
        />

        <EditClaimForm
            visible={isEditClaimFormVisible}
            onCreate={onCreateEditClaimForm}
            onCancel={() => { setEditClaimFormVisible(false); }}
          />

        <ClaimRoleForm
            visible={isClaimRoleFormVisible}
            onCreate={onCreateClaimRoleForm}
            onCancel={() => { setClaimRoleFormVisible(false); }}
          />

        <EditStepForm
            visible={isEditStepFormVisible}
            onCreate={onCreateEditStepForm}
            onCancel={() => { setEditStepFormVisible(false); }}
          />

        <EditLogForm
            visible={isEditLogFormVisible}
            onCreate={onCreateEditLogForm}
            onCancel={() => { setEditLogFormVisible(false); }}
          />

        <EditReminderForm
            visible={isEditReminderFormVisible}
            onCreate={onCreateEditReminderForm}
            onCancel={() => { setEditReminderFormVisible(false); }}
          />

        <EmailForm
            visible={isEmailFormVisible}
            onCreate={console.log("Create Email Form")}
            onCancel={() => { setEmailFormVisible(false); }}
          />

        <SMSForm
            visible={isSmsFormVisible}
            onCreate={console.log("Create SMS Form")}
            onCancel={() => { setSmsFormVisible(false); }}
          />

      </div>
    )
  }

}
export default protectedRoute(ClaimHome)
