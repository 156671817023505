import React from 'react'
import {Button, Image} from 'antd'
import { styles } from './Form'
import DCMLogo from './images/logoDCM2.png'

function SignUp({ updateFormState, signUp }) {
  return (
    <div style={styles.container}>
      <Image
        src={DCMLogo}
        preview={false}
      />
      <input
        name='username'
        onChange={e => {e.persist();updateFormState(e)}}
        style={styles.input}
        placeholder='email'
      />
      <input
        name='password'
        type='password'
        onChange={e => {e.persist();updateFormState(e)}}
        style={styles.input}
        placeholder='password'
      />
      <Button onClick={signUp}>Sign Up</Button>
    </div>
  )
}

export default SignUp
